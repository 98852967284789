import React, { useState, useEffect, useCallback } from 'react'
import confirm from 'reactstrap-confirm'
import { useParams } from 'react-router-dom'
import Avatar from 'react-avatar';
import _ from 'lodash';

import makeAPIRequest from '../../helpers/makeAPIRequest'
import Spinner from '../layouts/Spinner'
import toDateString from '../../helpers/toDateString'
import showNotification from '../../helpers/showNotification';

import PackageListing from './components/PackageListing'

import priceFormatter from '../../helpers/priceFormatter'

import styles from './styles/OrderDetails.module.css'
import { Button } from 'reactstrap'
import setStatusStyle from '../../helpers/setStatusStyle';
import toShortDateTimeString from '../../helpers/toShortDateString';

const defaultCurrency = process.env.REACT_APP_DEFAULT_CURRENCY

const OrderDetail = () => {
  const [order, setOrder] = useState()
  const [orderExist, setOrderExist] = useState(true)
  
  const { poId } = useParams();

  const getOrderDetails = useCallback( async () => {
    const orderDetail = await makeAPIRequest('get', `orders/${poId}`, {})

    if (orderDetail.status && orderDetail.status !== 200) {
      setOrderExist(false)
    } else {

      setOrder(orderDetail)
    }
  }, [poId])

  useEffect(() => {
    getOrderDetails()
  }, [getOrderDetails])

  const handlePoStatusChange = async (e) => {
    e.preventDefault()
    const status = e.target.value
    const poId = e.target.id
    const newValue = (document.getElementById(e.target.id).newValue)

    const confirmed = await confirm({
      title: 'PO Status Change Confirmation',
      message: (`Are you sure you want to change the status of the Purchase Order to ${status} ?`),
      confirmText: "Confirm",
      confirmColor: "secondary",
      cancelColor: "link text-danger"
    });

    if (confirmed) {
      const body = {
        poStatus: status
      }

      try {
        await makeAPIRequest('put', `orders/update/${poId}`, body)
        showNotification('success', 'PO Status Change', ['Your PO Status Update is successful.'])
        document.getElementById(e.target.id).newValue = status;
      } catch (error) {
        console.error(`Error during PO Status update for ${poId}`, error)
      }
    } else {
      document.getElementById(e.target.id).value = newValue ? newValue : order.poStatus;
    }
  }

  const [orderRemark, setOrderRemark] = useState(order?.remarks)

  const addOrderRemark = async (e) => {
    e.preventDefault()

    const body = { remarks: orderRemark }

    try {
      await makeAPIRequest('put', `orders/update/${poId}`, body)
      showNotification('success', 'Order Remark', ['Remark Added Success'])
    } catch (error) {
      console.error(`Error during PO Status update for ${poId}`, error)
    }

  }

  let sortedItemPackages = []

  if(order) {
    sortedItemPackages = Object.values(order.packages).sort((itemA, itemb) => itemA.packageId > itemb.packageId ? 1 : -1)
  }

  if (!order && !orderExist) {
    return (
      <div>
        <h5>Purchase Order Details for {poId} is not available.</h5>
      </div>
    )
  } else if (!order) {
    return (
      <Spinner />
    )
  }
  else {
    return (
      <div id='orderDetailContainer'>

        {Object.values(order.packages).some(x => x.status === "Pending Seller Acceptance") ?
          <div className="row slaRow" style={{ paddingBottom: "5px", padding: 10, borderRadius: 5, marginBottom: 15 }}>
            <span> Seller need to Accept this order by
              <span style={{ fontWeight: "bold" }}> {order.orderDueDate ? toShortDateTimeString(order.orderDueDate) : ""}</span> T&C applies.</span>
          </div>
        : ""}

        <h3>Order Details</h3>
        <hr />

        <div className="row alert alert-primary">
          <div className='col-sm-9'>
            <div className='row'>
              <label className='col-sm-3 col-form-label font-weight-bold'>Order ID:</label>
              <label className='col-sm-9 col-form-label'>{order.orderNumber}</label>
            </div>
            <div className='row'>
              <label className='col-sm-3 col-form-label font-weight-bold'>PO Number</label>
              <label className='col-sm-9 col-form-label'>{order.internalPO}</label>
            </div>
            <div className='row'>
              <label className='col-sm-3 col-form-label font-weight-bold'>Order Date:</label>
              <label className='col-sm-9 col-form-label'>{toDateString(order.createdAt)}</label>
            </div>
          </div>

          <div className='col-sm-3'>
            <div className='d-flex align-items-center' style={{ height: '50%'}}>
              <span className={`status-text align-self-center`} style={setStatusStyle(order.orderUrgency)}>Urgency : {order.orderUrgency}</span>
            </div>
            <div className='d-flex align-items-center' style={{ height: '50%'}}>
              <span className={`status-text align-self-center`} style={setStatusStyle(order.poStatus)}>{order.poStatus}</span>
            </div>
          </div>
        </div>

        <div className='row'>
          <label className='col-sm-3 col-form-label font-weight-bold'>Ordered By:</label>
          <label className='col-sm-9 col-form-label'>
            <Avatar src={order.createdBy?.companyLogo?.url} size='50' className='avatar-img' round={true} />
            <span> {order.createdBy?.companyName} </span>
            <span className='companyCategory' style={{ marginLeft: "4px" }}>
              {order.createdBy?.companyCategory}
            </span>
          </label>
        </div>

        <hr />

        <div>
          <h4 className="text-primary">Delivery Details</h4>
          <div className='row'>
            <h5 className='col-sm-3 col-form-label font-weight-bold'>Company Name:</h5>
            <label className='col-sm-9 col-form-label'>{order.shippingCompanyName}</label>
          </div>

          <div className='row'>
            <h5 className='col-sm-3 col-form-label font-weight-bold'>Shipping Address:</h5>
            <label className='col-sm-9 col-form-label'>
              {order.shipping?.addressLine1}<br />
              {order.shipping?.addressLine2}, {order.shipping?.addressLine3 ? order.shipping?.addressLine3 + ',' : ''} {order.shipping?.city}<br />
              {order.shipping?.postCode} {order.shipping?.state}, {order.shipping?.country}
            </label>
          </div>
          <div className='row'>
            <h5 className='col-sm-3 col-form-label font-weight-bold'>Phone:</h5>
            <label className='col-sm-9 col-form-label'>{order.phoneNumber || '---'}</label>
          </div>
          <div className='row'>
            <h5 className='col-sm-3 col-form-label font-weight-bold'>Attention to:</h5>
            <label className='col-sm-9 col-form-label'>{order.attToShipping || '---'}</label>
          </div>
          <br />
          <div className='row'>
            <h5 className='col-sm-3 col-form-label font-weight-bold'>Logistic Arrangement:</h5>
            <label className='col-sm-9 col-form-label'>{_.upperFirst(order.shipmentArrangement)} Arranged</label>
          </div>
          {
            _.upperFirst(order.shipmentArrangement) === "Self" && order.arrangedLogistic ?
              <div>
                <div className='row'>
                  <h5 className='col-sm-3 col-form-label font-weight-bold'>Logisitic Provider:</h5>
                  <label className='col-sm-9 col-form-label'>{order.arrangedLogistic.providerName}</label>
                </div>
                <div className='row'>
                  <label className='col-sm-9 offset-sm-3 col-form-label py-0'>Contact Person: {order.arrangedLogistic.contactName}</label>
                </div>
                <div className='row'>
                  <label className='col-sm-9 offset-sm-3 col-form-label py-0'>Contact Phone: {order.arrangedLogistic.contactPhone}</label>
                </div>
                <div className='row'>
                  <label className='col-sm-9 offset-sm-3 col-form-label py-0'>Contact Email: {order.arrangedLogistic.contactEmail}</label>
                </div>
                <div className='row'>
                  <label className='col-sm-9 offset-sm-3 col-form-label py-0'>Account Number: {order.arrangedLogistic.accountNumber}</label>
                </div>
              </div>

              : ""
          }
        </div>

        <hr />

        <div>
          <h4 className="text-primary">Payment Details</h4>
          <div className='row'>
            <h5 className='col-sm-3 col-form-label font-weight-bold'>Order Total:</h5>
            <label className='col-sm-9 col-form-label font-weight-bold text-success'>{defaultCurrency} {priceFormatter(order?.payment?.total, defaultCurrency)}</label>
          </div>
          <br />
        </div>

        <hr />

        {/* Note: comment it for now, not in use yet. Please remove it if it is no longer in use in future */}
        {/* <div>
          <label className={`${styles.poStatus}`}>PO Status: </label>
          <select name='poStatus' id={order.poId} onChange={handlePoStatusChange} defaultValue={order.poStatus}>
            <option value='Open'>Open</option>
            <option value='Completed'>Completed</option>
            <option value='Cancelled'>Cancelled</option>
          </select>
        </div> */}
        
        <p style={{ marginTop: 15, marginBottom: -10, fontSize: 16  }}>Order Remarks</p>
        <div style={{ display: 'flex', flex: 1, marginTop: 15 }}>
          <textarea className={`${styles.remarks}`} defaultValue={order.remarks} placeholder="remarks..." maxLength="250" onChange={(e) => setOrderRemark(e.target.value)}/>
          <Button outline className="ml-3" style={{ flex: 0.1 }} onClick={addOrderRemark}>Save</Button>
        </div>

        
        <div className='pt-4'>
          <div className='col-12 p-2 border bg-light'>
            <h6>Order Items</h6>
          </div>
          <div className='border'>
            { sortedItemPackages &&
              sortedItemPackages.map((orderPackage, index) => {
                return (
                  <PackageListing key={orderPackage.packageId} list={orderPackage} packageNo={index+1} orderId={order.poId} />
                );
              })
            }
          </div>
        </div>
      </div>
    )
  }
}

export default OrderDetail