import React, {useState, useEffect } from 'react'

import Listing from './Listing'
import SearchPagination from '../searchpagination/SearchPagination'
import NoResult from '../noresult/NoResult'
import Spinner from '../layouts/Spinner'
import makeAPIRequest from '../../helpers/makeAPIRequest'


const Wishlist = () => {
  const [wishlist, setWishlist] = useState([]);
  const [loading, setLoading] = useState(true);

  //  For pagination
  const [ currentPage, setCurrentPage ] = useState(1)
  const [ itemsPerPage ] = useState(20)

  const getWishlist = async() => {
    const result = await makeAPIRequest('get', 'wishlists/all', {})
    setWishlist(result)
    setLoading(false)
  }

  useEffect(() => {
    getWishlist();
  }, [])

  // To get the pagination order
  const indexOfLastItem = currentPage * itemsPerPage
  const indexOfFirstItem = indexOfLastItem - itemsPerPage
  const currentItem = wishlist.slice(indexOfFirstItem, indexOfLastItem)

  // To change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber)

  if (loading) {
    return (
      <Spinner />
    )
  } else {
    return (
      <div id="wishlist">
        <h3>Wishlists</h3>
        <br></br>
        { currentItem.length > 0 ? <Listing list={currentItem} /> : <NoResult />}
        <SearchPagination page={currentPage} size={itemsPerPage} total={wishlist.length} handleChange={paginate} />     
      </div>
    )
  }
}

export default Wishlist;
