import React from 'react';
import { useParams } from 'react-router-dom';

import ProductList from './ProductList';

import algoliasearch from 'algoliasearch';
import { InstantSearch, SearchBox, connectHits, connectStateResults, Pagination, HitsPerPage, connectRefinementList } from 'react-instantsearch-dom';
import Select from 'react-select'

import "./products.css"

const searchClient = algoliasearch(process.env.REACT_APP_ALGOLIAAPPID, process.env.REACT_APP_ALGOLIAPUBKEY);
const PRODUCTINDEX = process.env.REACT_APP_PRODUCTINDEX

const Products = () => {
  const { status } = useParams();

  const convertlist = (items) => {
    var result = [];
    items.forEach((item) => {
      var value = item.label
      var label = item.label;
      result.push({ value, label });
    });

    return result;
  }

  const convertItem = (item) => {
    if (item) {
      return { label: item, value: item }
    }
  };

  const customStyles = {
    control: (base, state) => ({
      ...base,
      background: "#E8E8E8",
      borderRadius: "5px",
      border: "0px",
      fontSize: "small"
    }),
    menu: base => ({
      ...base,
      borderRadius: 0,
      marginTop: 0,
      fontSize: "small"
    }),
    menuList: base => ({
      ...base,
      padding: 0,
      fontSize: "small"

    }),
  };

  const RefinementList = ({
    items,
    refine,
    defaultRefinement,
    placeholder
  }) => {
    return (
      <Select
        defaultValue={convertItem(defaultRefinement)}
        options={convertlist(items)} placeholder={placeholder} styles={customStyles} isMulti={true} hideSelectedOptions={true} isClearable={false}
        onChange={event => {
          let values = [];
          event.forEach((doc) => {
            values.push(doc.value);
          });
          refine(values)
        }}
      >
      </Select>
    )};

  const Hits = ({ hits }) => {
    return (
      <ProductList products={hits} />
    )
  }

  const StateResults = ({ searchResults }) => {
    const hasResults = searchResults && searchResults.nbHits !== 0;

    return (
      <div>
        <div hidden={hasResults}>
          <div className='text-center space-1'>
            <p className='card-text'>No data to show</p>
          </div>
        </div>
      </div>
    );
  };

  const CustomRefinementList = connectRefinementList(RefinementList);
  const CustomHits = connectHits(Hits);
  const CustomStateResults = connectStateResults(StateResults);


  return (
    <div id="membersContainer">
      <h3>Products</h3>
      <br />

      <InstantSearch
        indexName={PRODUCTINDEX}
        searchClient={searchClient}
      >
        <div className='row rowfilter' style={{ marginBottom: 10 }}>
          <div className='col-12 mb-3'>
            <SearchBox className='algoliasearch' translations={{ placeholder: 'Search By Product Name / Part Number / ATA / Description', showReset: false }} />
          </div>
          <div className='col-4'>
            <CustomRefinementList attribute="createdByCompanyName" placeholder="Listed By" />
          </div>
          <div className='col-4'>
            <CustomRefinementList placeholder="ATA" attribute="ata" />
          </div>
          <div className='col-4'>
            <CustomRefinementList attribute="materialClass" placeholder="Material" />
          </div>
          <div className='col-4 mt-3'>
            <CustomRefinementList attribute="condition" placeholder="Condition" />
          </div>
          <div className='col-4 mt-3'>
            <CustomRefinementList attribute="status" placeholder="Status" defaultRefinement={ status !== "all" ? [status] : ''} />
          </div>
        </div>

        <CustomHits />
        <CustomStateResults />

        <div className='row rowfilter'>
          <div className='col-12 text-right'>

            <HitsPerPage
              className="algoliahitsperpage"
              defaultRefinement={20}
              items={[
                { value: 20, label: 'Page Size: 20' },
                { value: 50, label: 'Page Size: 50' },
                { value: 100, label: 'Page Size: 100' },

              ]}
            />
            <Pagination showLast="true" />
          </div>
        </div>
      </InstantSearch>
    </div>
  )
}

export default Products;