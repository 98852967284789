import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import _ from 'lodash';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import confirm from "reactstrap-confirm";

import Spinner from '../layouts/Spinner';
import ProductInfo from '../productinfo/ProductInfo';
import ProductGallery from '../productgallery/ProductGallery';

import makeAPIRequest from '../../helpers/makeAPIRequest';

import './ProductDetail.css';

const ProductDetail = () => {
  const { productId } = useParams();

  const [loading, setLoading] = useState(true);
  const [product, setProductDetail] = useState();
  const [apimessage, setAPIMessage] = useState('');
  const [activeTab, setActiveTab] = useState('productDescription');

  const getProductDetail = async () => {
    const result = await makeAPIRequest('get', `products/${productId}`);
    if (_.get(result, 'error', false)) {
      return await setAPIMessage(result.message);
    }

    setProductDetail(result);
  }

  const toggleTab = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  }

  const setProductStatus = async status => {
    const confirmed = await confirm({
      title: 'Please confirm',
      message: (`Are you sure you want to change the product status?`),
      confirmText: "Confirm",
      confirmColor: "secondary",
      cancelColor: "link text-danger"
    });

    if (confirmed) {
      const result = await makeAPIRequest('put', `products/${productId}/status`, { status });
      setProductDetail({ ...product, status })
    }


  }

  useEffect(() => {
    getProductDetail();
    setLoading(false)
  }, []);

  if (loading) {
    return (
      <div className='p-5 h5'>
        <Spinner />
      </div>
    )
  } else {
    if (_.isEmpty(product)) {
      return (
        <div className='p-5 h5'>
          <p className='text-center'>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="mr-2 mb-1 text-danger bi bi-exclamation-triangle-fill" viewBox="0 0 16 16">
              <path fillRule="evenodd" d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 5zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
            </svg>
            {apimessage}
          </p>
        </div>
      )
    } else {
      let pillType = '';
      let status = '';
      switch (product.status) {
        case 'PENDING APPROVAL':
          pillType = 'warning';
          status = 'Pending Approval';
          break;
        case 'ACTIVE':
          pillType = 'success';
          status = 'Active';
          break;
        case 'DECLINED':
          pillType = 'warning';
          status = 'Declined';
          break;
        default:
          pillType = 'warning';
          status = 'Delisted'
      }

      return (
        <React.Fragment>
          <h3>Product Detail <span className={`badge badge-pill badge-${pillType} float-right`}>{status}</span></h3>
          <div className='row'>
            <div className='col-0 col-lg-7'>
              <div className='row border-bottom border-left border-right mr-1'>
                {
                  typeof product.images !== 'undefined' ? <ProductGallery images={product.images} /> : ''
                }
              </div>

              <div className='row border-left border-right border-bottom mr-1 pb-1 pt-4'>
                <div className='col p-0 m-0'>
                  <Nav tabs>
                    <NavItem>&nbsp;</NavItem>
                    <NavItem>
                      <NavLink className={'' + (activeTab === 'productDescription' ? 'active' : '')} onClick={() => { toggleTab('productDescription'); }} href='#description'>Description</NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink className={'' + (activeTab === 'productSpecification' ? 'active' : '')} onClick={() => { toggleTab('productSpecification'); }} href='#specification'>Specification</NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink className={'' + (activeTab === 'productDocuments' ? 'active' : '')} onClick={() => { toggleTab('productDocuments'); }} href="#documents">Documents</NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId="productDescription">
                      <div className="col mt-3">{product.description}</div>
                    </TabPane>

                    <TabPane tabId="productSpecification">
                      <div className='col mt-3'>
                        {
                          _.get(product, 'tsn', null) ? (
                            <div className='row border-bottom p-1'>
                              <div className='col'>Time Since New</div>
                              <div className='col text-right'>{_.get(product, 'tsn')}</div>
                            </div>
                          ) : ''
                        }

                        {
                          _.get(product, 'csn', null) ? (
                            <div className='row border-bottom p-1'>
                              <div className='col'>Cycle Since New</div>
                              <div className='col text-right'>{_.get(product, 'csn')}</div>
                            </div>
                          ) : ''
                        }

                        {
                          _.get(product, 'tso', null) ? (
                            <div className='row border-bottom p-1'>
                              <div className='col'>Time Since Overhaul</div>
                              <div className='col text-right'>{_.get(product, 'tso')}</div>
                            </div>
                          ) : ''
                        }

                        {
                          _.get(product, 'cso', null) ? (
                            <div className='row border-bottom p-1'>
                              <div className='col'>Cycle Since Overhaul</div>
                              <div className='col text-right'>{_.get(product, 'cso')}</div>
                            </div>
                          ) : ''
                        }

                        {product.warranty ?
                          <div className='row border-bottom p-1 even'>
                            <div className='col'>Warranty</div>
                            <div className='col text-right'>{product.warranty} {product.warrantyTime}</div>
                          </div> : ''
                        }

                        <div className='row border-bottom p-1 even'>
                          <div className='col'>On Stock</div>
                          <div className='col text-right'>{product.stock}</div>
                        </div>
                      </div>
                    </TabPane>

                    <TabPane tabId="productDocuments">
                      <div className='col mt-3'>
                        {
                          product.documents && product.documents.length > 0 ? (
                            product.documents.map((file, index) => {
                              if (product.naas && product.naas[index]) {
                                var country = product.naas[index].split('-')[0].trim();
                                var image_path = require("../../images/NAA/Flag_of_" + country.replace(new RegExp(" ", "g"), '_') + ".svg").default;
                              }
                              return (
                                <div className='row p-1' key={index}>
                                  <div className='col-12 col-lg-4 p-0 align-self-center text-center'>
                                    <button type="button" className="btn btn btn-primary btnDocumentTypes" style={{width : "100%"}} disabled>
                                      <i className="fas fa-file"></i>
                                      &nbsp;
                                      {product.documentTypes ? product.documentTypes[index] : "Others"}
                                    </button>
                                  </div>
                                  <div className='col-12 col-lg-2 p-0 align-self-center text-center'>
                                    {product.naas && product.naas[index] ?
                                      <img className='countryImage' src={image_path} title={product.naas[index]} alt="..." />
                                      : ""}
                                  </div>
                                  <div className='col-12 col-lg-4 align-self-center p-0'>
                                    <a rel='noreferrer' target='_blank' href={file.url}>
                                      {file.name} &nbsp;
                                      <i className="fas fa-download"></i>
                                    </a>
                                  </div>
                                </div>
                              )
                            })
                          ) : null
                        }
                      </div>
                    </TabPane>
                  </TabContent>
                </div>
              </div>
            </div>
            <div className='col-0 col-lg-5'>
              <ProductInfo product={product} />
            </div>
          </div>
          <div className='row py-3 mr-1'>
            <div className='col'>
              {product.status === 'PENDING APPROVAL' ? (
                <div className='text-center'>
                  <button className="btn btn-danger" type="button" onClick={(e) => setProductStatus('DECLINED')}>Decline</button>
                  <span className='mx-2'></span>
                  <button className="btn btn-success" type="button" onClick={(e) => setProductStatus('ACTIVE')}>Approve</button>
                </div>
              ) : ''
              }

              {product.status === 'ACTIVE' ? (
                <div className='text-center'>
                  <button className="btn btn-danger" type="button" onClick={(e) => setProductStatus('DELISTED')}>Delist</button>
                </div>
              ) : ''
              }

            </div>
          </div>
        </React.Fragment>
      )
    }



  }
}

export default ProductDetail;