export const orderUrgencyColor = {
  NORMAL: "#8E8D8D",
  CRITICAL:  "#FE951A",
  AOG:"#FE0000",
}

export const NORMAL = 'Normal'
export const CRITICAL = 'Critical'
export const AOG = 'AOG'

export const orderUrgencyOptions = [
  {
    name: 'Normal',
    value: NORMAL,
  },
  {
    name: 'Critical',
    value: CRITICAL,
  },
  {
    name: 'AOG',
    value: AOG,
  },
]