import React, { useReducer, useEffect } from "react";
import AuthContext from "./authContext";
import AuthReducer from "./authReducer";
import { RESET_CONTEXT, SET_CURRENT_USER, SET_ISLOGGEDIN, SET_LOADING } from "../types";

import { app } from "../../firebase";
import makeAPIRequest from "../../helpers/makeAPIRequest";

const AuthState = (props) => {
  const initialState = {
    currentUser: null,
    isLoggedIn: false,
    loading: true,
  };

  const [state, dispatch] = useReducer(AuthReducer, initialState);

  //Login with email
  const login = async (email, password) => {
    try {
      const isUserAdmin = await makeAPIRequest('get', `users/members/_public/isadmin/${email}`);
      
      if(isUserAdmin.isAdmin === true) {
        const result = await app.auth().signInWithEmailAndPassword(email, password);
        return result;
      } else {
        setIsLoggedIn(false);
        const error = new Error('Access denied.');
        error.code = 'Unauthorized';
        error.status = 401;
        throw error;
      }

    } catch(error) {
      throw error;
    }
  };

  //Logout
  const logout = async () => {
    clear();
    return await app.auth().signOut();
  };

  //Chgange Password
  const changePassword = async(currentPassword, newPassword) => {
    try {
      const currentUser = await app.auth().currentUser;
      const tryLogin = await login(currentUser.email, currentPassword);
      console.log(tryLogin)
      const update = await currentUser.updatePassword(newPassword);
      return update;
    } catch(error) {
      throw error;
    }
  }

  const userStateUpdate= async () => {
    const unsubscribe = await app.auth().onAuthStateChanged((user) => {
      dispatch({ type: SET_CURRENT_USER, payload: user });
      if(user) {
        setIsLoggedIn(true);
      }
    });
    return unsubscribe;
  }

  //Listen for app status changes
  useEffect(() => {

    return userStateUpdate();
  }, []);

  //Set loading
  const setLoading = (status) => {
    dispatch({ type: SET_LOADING, payload: status });
  };

  //Set isLoggedIn
  const setIsLoggedIn = (status) => {
    dispatch({ type: SET_ISLOGGEDIN, payload: status });
  };

  //reset contect
  const clear = () => {
    dispatch({ type: RESET_CONTEXT, payload: initialState });
  }

  return (
    <AuthContext.Provider
      value={{
        currentUser: state.currentUser,
        loading: state.loading,
        isLoggedIn: state.isLoggedIn,
        login,
        logout,
        setIsLoggedIn,
        changePassword,
        setLoading,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthState;
