import { RESET_CONTEXT, SET_LOADING, SET_ISLOGGEDIN, SET_CURRENT_USER } from "../types";

export default (state, action) => {
  switch (action.type) {
    case RESET_CONTEXT:
      return action.payload;
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case SET_CURRENT_USER:
      return {
        ...state,
        currentUser: action.payload,
        loading: false
      };
    case SET_ISLOGGEDIN:
      return {
        ...state,
        isLoggedIn: action.payload,
        loading: false
      };
    default:
      return state;
  }
};
