import React from "react";
import Pagination from "react-js-pagination";

import './SearchPagination.css';

const SearchPagination = ({page, size, total, handleChange, changeSize}) => {
  return (
    <div className="d-flex flex-row-reverse">
      <Pagination
        itemClass="page-item"
        linkClass="page-link"
        innerClass="pagination algolia-pagination"
        activePage={page}
        itemsCountPerPage={size}
        totalItemsCount={total}
        pageRangeDisplayed={5}
        onChange={handleChange}
      />
      { changeSize ? 
          <div className="dropdown search-pagesize mt-1 mr-3 d-none d-md-block">
          <button className="btn btn-sm btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Page Size: {size}
          </button>
          <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <a className="dropdown-item" href="#" onClick={() => changeSize(20)}>20</a>
            <a className="dropdown-item" href="#" onClick={() => changeSize(50)}>50</a>
            <a className="dropdown-item" href="#" onClick={() => changeSize(100)}>100</a>
          </div>
        </div> : ''
      }
    </div>
  )
}

export default SearchPagination;