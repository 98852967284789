import React, { useContext } from 'react';

const NoResult = () => {
  return (
    <div className='p-3 font-weight-bold text-center'>
      <div className='text-primary'>
        <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
          <path fillRule="evenodd" d="M10.442 10.442a1 1 0 0 1 1.415 0l3.85 3.85a1 1 0 0 1-1.414 1.415l-3.85-3.85a1 1 0 0 1 0-1.415z"/>
          <path fillRule="evenodd" d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z"/>
        </svg>
      </div>
      <h5>Sorry, we can't find any matches to your query!</h5>
    </div>
  )
}

export default NoResult;