import React, {useState, useEffect} from 'react';
import _ from 'lodash';
import confirm from "reactstrap-confirm";
import { useHistory } from "react-router-dom";

import makeAPIRequest from '../../../helpers/makeAPIRequest';
import Spinner from '../../layouts/Spinner';

const ProfileUpdate = ({userId}) => {
  const history = useHistory();

  const field = {
    companyName: 'Company Name',
    companyRegistrationNum: 'Registration Number',
    companyEmail: 'Company Email',
    companyPhone: 'Company Phone',
    companyAddress: 'Company Address',
    companyShipmentLimitation: 'Shipment Limitation',
    certificates: 'Local/FAA/EASA Approval Cert'
  }

  const [currentProfile, setCurrentProfile] = useState(null);
  const [updateProfile, setUpdateProfile] = useState(null);
  const [showSpinner, setShowSpinner] = useState(true);

  const getProfileUpdateInfo = async () => {
    const profileUpdate = await makeAPIRequest('get', `users/sellers/profileupdate/${userId}`, {});
    setCurrentProfile(profileUpdate.currentProfile)
    setUpdateProfile(profileUpdate.updateProfile)
    setShowSpinner(false);
  }

  const profileUpdateStatus = async (status) => {
    const confirmed = await confirm({
      title: 'Please confirm',
      message: (`Are you sure you want to change status to ${status}?`),
      confirmText: "Confirm",
      confirmColor: "secondary",
      cancelColor: "link text-danger"
    });

    if(confirmed) {
      setShowSpinner(true);
      const profileUpdateResult = await makeAPIRequest('put', `users/sellers/profileupdate/${userId}`, {status, id: updateProfile.id});
      setShowSpinner(false);
      history.goBack();
    }
  }

  useEffect(() => {
    getProfileUpdateInfo();
  }, []);

  if(showSpinner) {
    return <Spinner />
  } else {
    return (
      <div id="memberDetailContainer">
        <h3>Profile Update</h3>
        <hr/>
        <div className='row'>
          <div className='col'>
            <div className='p-4 border bg-light mb-3'>
              <h5>Current</h5>
              <hr/>
              {
                Object.keys(field).map((fieldId) => {
                  if(fieldId !== 'certificates') {
                    return (
                      <div className='row form-group'>
                        <label htmlFor='companyName' className='col-sm-4 col-form-label input-label'>{field[fieldId]}</label>
                        <div className='col-sm-8'>
                          <div className='input-group'>
                            <input type='text' className='form-control' id={fieldId} placeholder={field[fieldId]} readOnly value={currentProfile[fieldId]}/>  
                          </div>
                        </div>
                      </div>
                    )
                  } else {
                    return (
                      <div className='row form-group'>
                        <label htmlFor='companyName' className='col-sm-4 col-form-label input-label'>{field[fieldId]}</label>
                        <div className='col-sm-8'>
                          <div className='input-group'>
                            <ul className='list-group w-100'>
                            {currentProfile.certificates && currentProfile.certificates.map((file, index)=>(
                              <li className='list-group-item' key={index}>
                                <a target='_blank' href={file.location}>{file.fileName}</a>
                              </li>
                            ))}
                          </ul>  
                          </div>
                        </div>
                      </div>  
                    )
                  }
                })
              }
              
            </div>
            
          </div>
          <div className='col'>
            <div className='p-4 border bg-light mb-3'>
              <h5>Update</h5>
              <hr/>
              {
                Object.keys(field).map((fieldId) => {   
                  const value = _.get(updateProfile, fieldId, null) ? _.get(updateProfile, fieldId) : _.get(currentProfile, fieldId);
                  const isInvalid = _.get(updateProfile, fieldId, null) ? 'is-invalid' : '';

                  if(fieldId !== 'certificates') {
                    return (
                      <div className='row form-group'>
                        <label htmlFor='companyName' className='col-sm-4 col-form-label input-label'>{field[fieldId]}</label>
                        <div className='col-sm-8'>
                          <div className='input-group'>
                            <input type='text' className={`form-control ${isInvalid}`} id={fieldId} placeholder={field[fieldId]} readOnly value={value}/>  
                          </div>
                        </div>
                      </div>
                    )
                  } else {
                    return (
                      <div className='row form-group'>
                        <label htmlFor='companyName' className='col-sm-4 col-form-label input-label'>{field[fieldId]}</label>
                        <div className='col-sm-8'>
                          <div className='input-group'>
                            <ul className='list-group w-100'>
                            {value && value.map((file, index)=>(
                              <li className='list-group-item' key={index}>
                                <a target='_blank' href={file.location}>{file.fileName}</a>
                              </li>
                            ))}
                          </ul>  
                          </div>
                        </div>
                      </div>  
                    )
                  }
                })
              }
            </div>
          </div>
        </div>
        <div className='row form-group'>
          <div className='col text-center'>
            <button type='reset' className='btn btn-danger' onClick={(event) => profileUpdateStatus('DECLINED')}>Decline</button>
            <span className='mx-2'></span>
            <button type='submit' className='btn btn-primary' onClick={(event) => profileUpdateStatus('APPROVED')}>Approve</button>
          </div>
        </div>
      </div>
    )
  }
  }

  

export default ProfileUpdate;