import { store } from 'react-notifications-component';
import NotificationContent from '../components/notificationcontent/NotificationContent';

const showNotification = (type = 'danger', title='Error', contents = []) => {
  store.addNotification({
    title: title,
    message: <NotificationContent contents={contents} />,
    type: type,
    insert: "top",
    container: "top-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 10000,
      onScreen: false
    }
  });
}

export default showNotification;