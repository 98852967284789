//OLD
export const orderStatusColors = {
  PENDING_SELLER_ACCEPTANCE: "#FE951A",
  ORDER_ACCEPTED: "#18AB00",
  PENDING_LOGISTICS: "#FE951A",
  ORDER_DECLINED: "#FE0000",
  ORDER_CANCELLED: "#FE0000",
  LOGISTICS_ARRANGED: "#FE951A",
  SHIPPED: "#18AB00",
  SHIPPED_SUCCESS : "#18AB00" ,
  RECEIVING_COMPLETE : "#18AB00" ,
  RECEIVING_FAILED : "#18AB00" ,
  PENDING_PAYMENT : "#FE0000" ,
  PAYMENT_RECEIVED : "#18AB00" ,
  COMPLETED : "#18AB00" ,
  ORDER_COMPLETED : "#18AB00" ,
  ORDER_CONFIRMED : "#18AB00" ,
  CANCELLED : "#FE0000" ,
  PENDING_REFUND : "#FE0000",
  ORDER_REFUNDED : "#18AB00",
  ORDER_PARTIALLY_ACCEPTED: "#FE951A",
  PENDING_BUYER_ACCEPTANCE: "#FE951A",
  PROCESSING: "#18AB00",
  NORMAL: "#8E8D8D",
  CRITICAL:  "#FE951A",
  AOG:"#FE0000",
}

export const orderPackageStatusConstants = {
  PENDING_SELLER_ACCEPTANCE: 'Pending Seller Acceptance',
  ORDER_ACCEPTED: 'Order Accepted',
  ORDER_DECLINED: 'Order Declined',
  ORDER_CANCELLED: 'Order Cancelled',
  PENDING_LOGISTICS: 'Pending Logistics',
  PROCESSING: 'Processing',
  PICKED_UP: 'Picked Up By Forwarder'
}

export const orderStatus = {
  PENDING_SELLER_ACCEPTANCE: 'Pending Seller Acceptance',
  ORDER_DECLINED: 'Order Declined',
  ORDER_CONFIRMED: 'Order Confirmed',
  ORDER_PARTIALLY_ACCEPTED: 'Order Partially Accepted',
  ORDER_CANCELLED: 'Order Cancelled',
  PENDING_LOGISTICS: 'Pending Logistics',
  PENDING_BUYER_ACCEPTANCE: 'Pending Buyer Acceptance'
}


//NEW
export const orderStatuses = {
  PENDING_SELLER_ACCEPTANCE: "#FE951A",
  ORDER_ACCEPTED: "#18AB00",
  PENDING_LOGISTICS: "#FE951A",
  ORDER_DECLINED: "#FE0000",
  ORDER_CANCELLED: "#FE0000",
  LOGISTICS_ARRANGED: "#FE951A",
  SHIPPED: "#18AB00",
  SHIPPED_SUCCESS : "#18AB00" ,
  RECEIVING_COMPLETE : "#18AB00" ,
  RECEIVING_FAILED : "#18AB00" ,
  PENDING_PAYMENT : "#FE0000" ,
  PAYMENT_RECEIVED : "#18AB00" ,
  COMPLETED : "#18AB00" ,
  ORDER_COMPLETED : "#18AB00" ,
  CANCELLED : "#FE0000" ,
  PENDING_REFUND : "#FE0000",
  ORDER_REFUNDED : "#18AB00",
  ORDER_PARTIALLY_ACCEPTED: "#FE951A",
  PENDING_BUYER_ACCEPTANCE: "#FE951A",
}
