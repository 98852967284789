import React,{useState,useEffect} from 'react';
import ReactPaginate from 'react-paginate';
import DatePicker from "react-datepicker";

import makeAPIRequest from '../../helpers/makeAPIRequest';
import './BCLog.css';

import LogList from './LogList';
import algoliasearch from 'algoliasearch';

const searchClient = algoliasearch(process.env.REACT_APP_ALGOLIAAPPID, process.env.REACT_APP_ALGOLIAPUBKEY);
const LOGINDEX = 'stg_logs'

const BCLog = () => {

  const [logs,setLogs] = useState([])
  const [selectedLogs,setSelectedLogs] = useState([])
  const [sliceLogs,setSliceLogs] = useState([])
  const [pageCount,setPageCount] = useState(0)
  const [perPage,setPerPage] = useState(5)
  const [currentPage,setCurrentPage] = useState(0)
  const [offset,setOffset] = useState(0)
  const [inputSearch,setInputSearch] = useState("")

  const fetchLogs=async()=>{
    //const response = await app.firestore().collection('members').where('email', '==', "testadmin@airasia.com").get();
    //const logId = "123|456"
    //const data = await makeAPIRequest('get', `logs/${logId}`, {})
    const data = await makeAPIRequest('get', `logs`, {})
    if (data != null) {
      console.log(data)
      let newLogs = []

      data.map((item) =>{
        console.log(item)
        newLogs = [...newLogs, item];
      })
      setLogs(newLogs)
      setSelectedLogs(newLogs)
      //console.log(selectedLogs)
      setPageCount(Math.ceil(newLogs.length / perPage))
      const sliceLogs = newLogs.slice(offset, offset + perPage)

      setSliceLogs(sliceLogs)
    }
  }

  useEffect(() => {
    //setLogs([])
    fetchLogs();
  }, [])

  useEffect(() => {
    console.log('useEffect3')
    const sliceLogs = selectedLogs.slice(offset, offset + perPage)
    console.log(sliceLogs)
    setSliceLogs(sliceLogs)
  }, [currentPage])

  useEffect(() => {
    console.log('useEffect2')
    const sliceLogs = selectedLogs.slice(offset, offset + perPage)
    console.log(sliceLogs)
    setSliceLogs(sliceLogs)
  }, [offset])

  useEffect(() => {
    console.log('useEffect1')
    setCurrentPage(0)
    setOffset(0)
    console.log(`offset ${offset} ${currentPage}`)
    const sliceLogs = selectedLogs.slice(offset, offset + perPage)
    console.log(sliceLogs)
    setSliceLogs(sliceLogs)
  }, [selectedLogs])

  const handleInputChange = (param) => {
    //console.log(param.target.value)
    setInputSearch(param.target.value)
    //console.log(dateRange)
    var startTimestamp = (new Date(dateRange[0])).getTime();
    var endTimestamp = dateRange[1] ? (new Date(dateRange[1]).addDays(1)).getTime() : 999999999999999999;
    //console.log(startTimestamp, endTimestamp)
 
    const data = logs.filter((item) => item.api.startsWith(param.target.value))
    const searchResult = data.filter((item) => {
      const id = (new Date(item.createdAt).getTime())
      const si = (id) >= startTimestamp && (id) <= endTimestamp
      //console.log(si)
      return si
    })
    //console.log(searchResult)
    setSelectedLogs(searchResult);
    setPageCount(Math.ceil(searchResult.length / perPage))
  }

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    console.log(`selectedPage ${selectedPage}`)
    const offset = selectedPage * perPage;

    setCurrentPage(selectedPage)
    setOffset(offset)
  };

  const searchByDate = (start, end) => {
    var startTimestamp = (new Date(start)).getTime();
    var endTimestamp = end ? (new Date(end).addDays(1)).getTime() : 999999999999999999;
    //console.log(startTimestamp, " " , endTimestamp, inputSearch)
    const data = logs.filter((item) => item.api.startsWith(inputSearch))
    const searchResult = data.filter((item) => {
      const id = (new Date(item.createdAt).getTime())
      //console.log(id)
      const si = (id) >= startTimestamp && (id) <= endTimestamp
      return si
    })
    //console.log(searchResult)
    setSelectedLogs(searchResult);
    setPageCount(Math.ceil(searchResult.length / perPage))
  }

  const clearDate = () => {
    const searchResult = logs.filter((item) => item.api.startsWith(inputSearch))
    setSelectedLogs(searchResult);
    setPageCount(Math.ceil(searchResult.length / perPage))
  }

  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  Date.prototype.addDays = function (days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
  }

  return (
    <div id="membersContainer">
      <h3>Blockchain Log</h3>
      {}

      <table>
        <tr>
          <td>
          <label htmlFor="search" style={{ marginRight: '.5rem' }} >Search by API</label><br/>
          <input type="text" style={{ marginRight: '.5rem' }} placeholder="API name" onChange={handleInputChange}/>
          </td>
          <td colSpan="4">{}</td>
          <td>
          <text htmlFor="search"  >Search by date</text>
          <DatePicker className="form-control"
                min={0}
                max={9999999999}
                placeholderText="Log Date"
                selected={startDate}
                startDate={startDate}
                endDate={endDate}
                selectsRange={true}
                isClearable={true}
                onChange={(dates) => {
                  setDateRange(dates);
                  const [start, end] = dates;
                  if (start) {
                    searchByDate(start, end)
                  } else {
                    clearDate()
                  }
              }}
          />
          </td>
        </tr>
        <tr><td>{}</td></tr>
        <tr><td>{}</td></tr>
      </table>
      {}

      <div className="row">
        <div className='col-6'>
        {
          <table className="table table-striped table-hover">
          <thead>
            <tr>
              <th></th>
              <th>Id</th>
              <th>Api</th>
              <th>Response</th>
              <th>DateTime</th>
            </tr>
          </thead>
          <tbody>
          {
            sliceLogs && sliceLogs.map(log => {
              return(
                 <tr key={log.id}>
                 <td></td>
                  <td>{log.id}</td>
                  <td>{log.api}</td>
                  <td>{log.response}</td>
                  <td>{log.createdAt}</td>
                </tr>
              )
            })
          }
          </tbody>
          </table>      
        }
        <ReactPaginate
          previousLabel={"prev"}
          nextLabel={"next"}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
          forcePage={currentPage}/>
        </div>
      </div>

    </div>
  )
}

export default BCLog;