import { app } from "../firebase";
import axios from 'axios';

import showNotification from './showNotification';

const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;

const makeAPIRequest = async (method, endPoint, data, apiURL = BASE_API_URL) =>  {
  const user = await app.auth().currentUser;
  
  const reqHeader = { 
    headers: {
      'Content-Type': 'application/json'
    }
  };

  if (user) {
    const token = await user.getIdToken(true);
    reqHeader.headers.Authorization = `Bearer ${token}`;
  }
  
  let result = null;
  try {
    switch(method) {
      case 'get':
        result = await axios.get(`${apiURL}/${endPoint}`, reqHeader);
        break;
      case 'delete':
        result = await axios.delete(`${apiURL}/${endPoint}`, reqHeader);
        break;
      case 'post':
        result = await axios.post(`${apiURL}/${endPoint}`, data, reqHeader);
        break;
      case 'put':
        result = await axios.put(`${apiURL}/${endPoint}`, data, reqHeader);
        break;
      default:
        result = {message: 'Unknow method.'};
    }
    
    return result.data;
  } catch(error) {
    const message = error.response.data.message;
    showNotification('danger', 'Error', [message]);
    return {error: true, message};
  }
}

export default makeAPIRequest;

