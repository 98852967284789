import React, { useRef, useContext } from 'react';
import AuthContext from "../../context/auth/authContext";

import showNotification from '../../helpers/showNotification';

import './ChangePassword.css';

const ChangePassword = () => {
  const authContext = useContext(AuthContext)

  const currentPassword = useRef(null);
  const newPassword = useRef(null);
  const confirmPassword = useRef(null);

  const handleSubmit = async (e) => {
    e.preventDefault();

    let errors = [];
    if (!currentPassword.current.value) {
      errors.push('Current password is required.');
    }

    if (!newPassword.current.value) {
      errors.push('New password is required.');
    }

    if (!confirmPassword.current.value) {
      errors.push('Confirm password is required.');
    }

    if (confirmPassword.current.value !== newPassword.current.value) {
      errors.push('New password does not match with confirm password.');
    }

    if(errors.length > 0) {
      return showNotification("danger", "Please correct the following:", errors);
    }

    try {
      const result = await authContext.changePassword(currentPassword.current.value, newPassword.current.value);
      currentPassword.current.value = '';
      newPassword.current.value = '';
      confirmPassword.current.value = '';

      showNotification("success", "Success:", ['Password has been updated.']);

      return result;
    } catch (error) {
      return showNotification("danger", "Error:", [error.message]);
    }
  }

  return (
    <div id="changePassword">
      <h3>Change Password</h3>
      <hr/>
      <div className="row">
        <div className="col">
          <form className='m-width' onSubmit={handleSubmit} c>
            <div className="form-group row">
              <label htmlFor="currentPassword" className="col-sm-4 col-lg-3 col-form-label">Current Password</label>
              <div className="col-sm-8 col-lg-9">
                <input type="password" className="form-control" ref={currentPassword} id="currentPassword" placeholder="Current Password" />
              </div>
            </div>
            <div className="form-group row">
              <label htmlFor="newPassword" className="col-sm-4 col-lg-3 col-form-label">New Password</label>
              <div className="col-sm-8 col-lg-9">
                <input type="password" className="form-control" ref={newPassword} id="newPassword" placeholder="New Password" />
              </div>
            </div>
            <div className="form-group row">
              <label htmlFor="confirmPassword" className="col-sm-4 col-lg-3 col-form-label">Confirm New Password</label>
              <div className="col-sm-8 col-lg-9">
                <input type="password" className="form-control" ref={confirmPassword} id="confirmPassword" placeholder="Confirm New Password" />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-4 col-lg-3 col-form-label"></div>
              <div className="col-sm-8 col-lg-9">
              <button type="submit" className="btn btn-primary btn-block">Submit</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>    
  )
}

export default ChangePassword;