import React, { useState } from 'react';
import { upperFirst } from 'lodash';
import OrderList from './OrderList';
import algoliasearch from 'algoliasearch';
import { InstantSearch, SearchBox, connectHits, connectStateResults, Pagination, HitsPerPage, connectRefinementList, connectRange } from 'react-instantsearch-dom';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useParams } from 'react-router-dom';

import "./orders.css"

const searchClient = algoliasearch(process.env.REACT_APP_ALGOLIAAPPID, process.env.REACT_APP_ALGOLIAPUBKEY);
const ORDERINDEX = process.env.REACT_APP_ORDERINDEX;

const Orders = () => {
    const { status } = useParams();

    const convertlist = (items, placeholder) => {
        var result = [];
        if (placeholder == "Shipment") {
            items.forEach((item) => {
                var value = item.label;
                var label = upperFirst(item.label) + " Arranged";
                result.push({ value, label });
            });
        }
        else {

            items.forEach((item) => {
                var value = item.label;
                var label = item.label;
                result.push({ value, label });
            });
        }
        return result;
    }

    const customStyles = {
        control: (base, state) => ({
            ...base,
            background: "#E8E8E8",
            borderRadius: "5px",
            border: "0px",
            fontSize: "small"
        }),
        menu: base => ({
            ...base,
            borderRadius: 0,
            marginTop: 0,
            fontSize: "small"
        }),
        menuList: base => ({
            ...base,
            padding: 0,
            fontSize: "small"

        }),
    };

    const RefinementList = ({
        items,
        refine,
        placeholder,
        defaultRefinement
    }) =>
    (
        <Select options={convertlist(items, placeholder)} defaultRefinement={[defaultRefinement]}
            defaultValue={defaultRefinement ? [{ label: defaultRefinement, value: defaultRefinement }] : []}
            placeholder={placeholder} styles={customStyles} isMulti={true} hideSelectedOptions={true} isClearable={false}
            onChange={event => {
                let values = [];
                event.forEach((doc) => {
                    values.push(doc.value);
                });
                refine(values)
            }}
        >
        </Select>
    );

    const Hits = ({ hits }) => {
        return (
            <OrderList orders={hits} />
        )
    }

    const StateResults = ({ searchResults }) => {
        const hasResults = searchResults && searchResults.nbHits !== 0;

        return (
            <div>
                <div hidden={hasResults}>
                    <div className='text-center space-1'>
                        <p className='card-text'>No data to show</p>
                    </div>
                </div>
            </div>
        );
    };

    const RangeInput = ({ currentRefinement, min, max, precision, refine }) => {
        const [dateRange, setDateRange] = useState([null, null]);
        const [startDate, endDate] = dateRange;
        return (<DatePicker className="form-control"
            min={min}
            max={max}
            placeholderText="Order Date"
            dateFormat="MMM dd yyyy"
            selected={startDate}
            startDate={startDate}
            endDate={endDate}
            selectsRange={true}
            isClearable={true}
            onChange={(dates) => {
                setDateRange(dates);
                const [start, end] = dates;
                if (start) {
                    var startTimestamp = (new Date(start)).getTime();
                    var endTimestamp = end ? (new Date(end).addDays(1)).getTime() : 999999999999999999;
                    refine({
                        ...currentRefinement,
                        min: startTimestamp,
                        max: endTimestamp
                    })
                } else {
                    refine({
                        ...currentRefinement,
                        min: "",
                        max: ""
                    })
                }
            }}
        />
        );
    };

    Date.prototype.addDays = function (days) {
        var date = new Date(this.valueOf());
        date.setDate(date.getDate() + days);
        return date;
      }

    const CustomRangeInput = connectRange(RangeInput);
    const CustomRefinementList = connectRefinementList(RefinementList);
    const CustomHits = connectHits(Hits);
    const CustomStateResults = connectStateResults(StateResults);


    return (
        <div id="membersContainer">
            <h3>Orders</h3>
            <br />

            <InstantSearch
                indexName={ORDERINDEX}
                searchClient={searchClient}
            >
                <div className='row rowfilter' style={{ marginBottom: 10 }}>
                    <div className='col-12 mb-3'>
                        <SearchBox className='algoliasearch' translations={{ placeholder: 'Search By Order ID / PO Number' }} />
                    </div>
                    <div className='col-4'>
                        <CustomRefinementList placeholder="Ordered By" attribute="buyer.companyName" />
                    </div>
                    <div className='col-4'>
                        <CustomRefinementList placeholder="Sold By" attribute="seller.companyName" />
                    </div>
                    <div className='col-4'>
                        <CustomRefinementList placeholder="Order Status" attribute="poStatus" defaultRefinement={ status !== "all" ? [status] : ''} />
                    </div>
                    <div className='col-4 mt-3'>
                        <CustomRefinementList placeholder="Shipment" attribute="shipmentArrangement" />
                    </div>
                    <div className='col-4 mt-3'>
                        <CustomRefinementList placeholder="Order Urgency" attribute="orderUrgency" />
                    </div>
                    <div className='col-4 mt-3'>
                        <CustomRangeInput min={0} max={999999999999999999} attribute="createdAtTimestamp" placeholder="Order Date" />
                    </div>
                </div>

                <CustomHits />
                <CustomStateResults />

                <div className='row rowfilter'>
                    <div className='col-12 text-right'>

                        <HitsPerPage
                            className="algoliahitsperpage"
                            defaultRefinement={20}
                            items={[
                                { value: 20, label: 'Page Size: 20' },
                                { value: 50, label: 'Page Size: 50' },
                                { value: 100, label: 'Page Size: 100' },

                            ]}
                        />
                        <Pagination showLast="true" />
                    </div>
                </div>
            </InstantSearch>
        </div>
    )
}

export default Orders;