import _ from 'lodash';
import React, {Fragment} from 'react';
import confirm from 'reactstrap-confirm'

import showNotification from '../../../helpers/showNotification';
import makeAPIRequest from '../../../helpers/makeAPIRequest';
import priceFormatter from "../../../helpers/priceFormatter"
import DefaultImg from '../../../images/ade-default-product.png'

import { Button } from 'reactstrap';
import setStatusStyle from '../../../helpers/setStatusStyle';

const defaultCurrency = process.env.REACT_APP_DEFAULT_CURRENCY

const orderStatus = ['Pending Logistics', 'Logistics Arranged', 'Shipped', 'Shipped Success', 'Receiving Complete', 'Receiving Failed', 'Pending Payment', 'Payment Received', 'Completed', 'Cancelled']

const PackageListing = ({ list, packageNo, orderId }) => {
  const getStatusDropDownOptions = () => {
    let list = []
    _.forEach(orderStatus, (status) => {
      list.push(<option value={status}>{status}</option>)
    })

    return list
  }

  let statusDropDownOption = getStatusDropDownOptions()

  const handleStatusDropdownChange = async (e, defaultListValue) => {
    e.preventDefault()
    const status = e.target.value
    const newValue = (document.getElementById(e.target.id).newValue)

    const confirmed = await confirm({
      title: 'Package Status Change Confirmation',
      message: (`Are you sure you want to change the status of the package with the ID : ${e.target.id} to ${e.target.value} ?`),
      confirmText: "Confirm",
      confirmColor: "secondary",
      cancelColor: "link text-danger"
    });

    if (confirmed) {
      await makeAPIRequest('put', `orders/package/${e.target.id}`, { status })
      showNotification('success', 'Package Status Change', ['Your update is successful.'])
      document.getElementById(e.target.id).newValue = status;
    } else {
      document.getElementById(e.target.id).value = newValue ? newValue : defaultListValue;
    }
  }

  const [itemRemark, setItemRemark] = React.useState(list.remarks)

  const saveItemRemark = async () => {
    const objectKey = `packages.${list.packageId}.remarks`

    const body = {
      [objectKey]: itemRemark
    }

    try {
      await makeAPIRequest('put', `orders/update/${orderId}`, body)
      showNotification('success', 'Item Remark', ['Remark Added Success'])
    } catch (error) {
      console.error(`Error during PO Status update for ${list.packageId}`, error)
    }
  }

  return (
    <div style={{ borderBottomWidth: 1, borderBottomColor: 'lightgrey', borderBottomStyle: 'solid', paddingLeft: 20, paddingRight: 20 }}>
      <div className="row pt-3 pb-3">
        <div className='col-9' style={{ marginTop: 15 }}>
          <h6>Order Item ID {packageNo}: {list.packageId}</h6>
        </div>
        {/* <div className={`col-3`} style={{ textAlign: 'center' }}>
          <h6 className={`${styles.statusTag}`}>{list.status}</h6>
        </div> */}
      </div>
      <table className="table" style={{ tableLayout: 'fixed', width: '100%' }}>
        <tbody>
          {
            list.products.map((order, key) => {
              return (
                <Fragment key={key}>
                  <tr>
                    <td><img src={order.images ? order.images[0].url : DefaultImg} style={{width: 120}} alt='Products'/></td>
                    <td><b>{order.name}</b> {order.chapter.category.category} - {order.chapter.chapter.ataChapterName}
                    </td>
                    <td>{order.quantity} {order.uom}</td>
                    <td>{order.currency ? order.currency : defaultCurrency} {priceFormatter(order.total, order.currency ? order.currency : defaultCurrency )}</td>
                    <td>
                      {/* Note: comment for now, please remove it in future if no longer in use */}
                      {/* <select name='status' id={list.packageId} onChange={(e) => handleStatusDropdownChange(e, list.status)} defaultValue={list.status}>
                        {statusDropDownOption}
                      </select> */}
                      <div className='d-flex align-items-center' style={{ height: '50%'}}>
                        <span className={`status-text align-self-center`} style={setStatusStyle(list.status)}>{list.status}</span>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td colSpan='5'>
                      <label>Item Remarks:</label>
                      {/* text area input */}
                      <div style={{ display: 'flex', flex: 1 }}>
                        <input type="textarea" className="form-control" id="remarks" placeholder="remarks..." onChange={(event) => setItemRemark(event.target.value)} value={itemRemark}/>
                        <Button outline style={{ marginLeft: 10, width: 150 }} onClick={() => saveItemRemark(order)}>
                          Save
                        </Button>
                      </div>
                    </td>
                  </tr>
                </Fragment>
              )
            })
          }
        </tbody>
      </table>
     
      <div className="footer">
        <div className="stats"><i></i></div>
      </div>
    </div>
  )
}

export default PackageListing;