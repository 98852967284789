import React, {useState, useEffect} from 'react';
import confirm from "reactstrap-confirm";
import validator from 'validator';
import { useHistory } from 'react-router-dom'

import makeAPIRequest from '../../helpers/makeAPIRequest';
import showNotification from '../../helpers/showNotification';

const SellerInfo = ({userId, sInfo, getUserDetails}) => {
  const api = sInfo.status === 'APPROVED' ? 'sellers' : 'members';

  const history = useHistory();
  
  const [sellerInfo, setSellerInfo]= useState(sInfo)

  const fieldNames = {
    companyName: 'Company Name',
    companyRegistrationNum: 'Registration Number',
    companyEmail: 'Company Email',
    companyPhone: 'Company Phone',
    companyAddress: 'Company Address',
    companyShipmentLimitation: 'Shipment Limitation'
  }

  const changeSellerStatus = async (status) => {
    if( validateSellerInfo() ) {
      const confirmed = await confirm({
        title: 'Please confirm',
        message: (`Are you sure you want to change Seller Application status?`),
        confirmText: "Confirm",
        confirmColor: "secondary",
        cancelColor: "link text-danger"
      }); 

      if(confirmed) {
        const data = sellerInfo;
        const result = await makeAPIRequest('put', `users/${api}/sellerinfo/${userId}/status`,{...sellerInfo, status});
        showNotification('success', 'Seller Info', ['Change status is successfull.']);
        setSellerInfo({...sellerInfo, status})
        history.replace(`/admin/users/sellers/view/${userId}`);
      }
    }    
  }

  const updateSellerInfo = async () => {
    if( validateSellerInfo() ) {
      const result = await makeAPIRequest('put', `users/${api}/sellerinfo/${userId}/update`, sellerInfo);
      showNotification('success', 'Seller Info', ['Update info is successfull.']);
      setSellerInfo(sellerInfo)
    }
  }

  const validateSellerInfo = (event) => {
    let errors = [];
    
    Object.keys(fieldNames).map((fieldId) => {
      const value = sellerInfo[fieldId];
      if(!value || value.length === 0) {
        errors.push(fieldNames[fieldId]+' is required.');
      }
      return (!value);
    })
    
    if(errors.length > 0) {
      showNotification("danger", "Please correct the following:", errors);
      return false;
    }
    
    return true;
  }

  const handleChange = (event) => {
    setSellerInfo({
      ...sellerInfo,
      [event.target.id]: event.target.value
    });
  }

  useEffect(() => {
    getUserDetails();
  }, [sellerInfo]);

  if( !sellerInfo ) {
    return (
      <React.Fragment>
        <h5>Seller Info</h5>
        <hr/>
        <p>Member has no application to become a Seller.</p>
      </React.Fragment>  
    )
  } else {
    let pillType = '';
    let status = ''
    switch (sellerInfo.status) {
      case 'DECLINED':
        pillType = 'danger';
        status = 'Declined';
        break;
      case 'REVOKED':
        pillType = 'danger';
        status = 'Revoked';
        break;  
      case 'APPROVED':
        pillType = 'success';
        status = 'Approved';
        break;
      default:
        pillType = 'warning';
        status = 'Pending'
    }
    return (
      <React.Fragment>
        {
          <span className={`badge badge-pill badge-${pillType} float-right`}>{status}</span>
        }
        <h5>Seller Info</h5>
        <div className='row form-group'>
          <label htmlFor='companyName' className='col-sm-4 col-form-label input-label'>{fieldNames.companyName}</label>
          <div className='col-sm-8'>
            <div className='input-group'>
              <input type='text' className='form-control' id='companyName' placeholder={fieldNames.companyName} readOnly value={sellerInfo.companyName} onChange={handleChange}/>  
            </div>
          </div>
        </div>
  
        <div className='row form-group'>
          <label htmlFor='companyRegistrationNum' className='col-sm-4 col-form-label input-label'>{fieldNames.companyRegistrationNum}</label>
          <div className='col-sm-8'>
            <div className='input-group'>
              <input type='text' className='form-control' id='companyRegistrationNum' placeholder={fieldNames.companyRegistrationNum} readOnly value={sellerInfo.companyRegistrationNum} onChange={handleChange}/>  
            </div>
          </div>
        </div>
  
        <div className='row form-group'>
          <label htmlFor='companyEmail' className='col-sm-4 col-form-label input-label'>{fieldNames.companyEmail}</label>
          <div className='col-sm-8'>
            <div className='input-group'>
              <input type='text' className='form-control' id='companyEmail' placeholder={fieldNames.companyEmail} required value={sellerInfo.companyEmail} onChange={handleChange}/>  
            </div>
          </div>
        </div>
  
        <div className='row form-group'>
          <label htmlFor='companyPhone' className='col-sm-4 col-form-label input-label'>{fieldNames.companyPhone}</label>
          <div className='col-sm-8'>
            <div className='input-group'>
              <input type='text' className='form-control' id='companyPhone' placeholder={fieldNames.companyPhone} required value={sellerInfo.companyPhone} onChange={handleChange}/>  
            </div>
          </div>
        </div>
  
        <div className='row form-group'>
          <label htmlFor='companyAddress' className='col-sm-4 col-form-label input-label'>{fieldNames.companyAddress}</label>
          <div className='col-sm-8'>
            <div className='input-group'>
              <textarea rows='4' cols='50' className='form-control' id='companyAddress' placeholder={fieldNames.companyAddress} onChange={handleChange} defaultValue={sellerInfo.companyAddress}></textarea>
            </div>
          </div>
        </div>
  
        <div className='row form-group'>
          <label htmlFor='companyShipmentLimitation' className='col-sm-4 col-form-label input-label'>{fieldNames.companyShipmentLimitation}</label>
          <div className='col-sm-8'>
            <div className='input-group'>
              <input type='text' className='form-control' id='companyShipmentLimitation' placeholder={fieldNames.companyShipmentLimitation} required value={sellerInfo.companyShipmentLimitation} onChange={handleChange}/>  
            </div>
          </div>
        </div>
        
        <div className='row form-group'>
          <label htmlFor='certificates' className='col-sm-4 col-form-label input-label'>Local/FAA/EASA Approval Cert</label>
          <div className='col-sm-8'>
            <ul className='list-group mt-2'>
              {sellerInfo.certificates && sellerInfo.certificates.map((file, index)=>(
                <li className='list-group-item' key={index}>
                  <a target='_blank' href={file.location}>{file.fileName}</a>
                </li>
              ))}
            </ul>
          </div>
        </div>
  
        {
          sellerInfo.status === 'PENDING APPROVAL' ? (
            <div className='row form-group'>
              <div className='col-sm-4'></div>
              <div className='col-sm-8 text-center'>
                <button type='reset' className='btn btn-danger' onClick={(event) => changeSellerStatus('DECLINED')}>Decline</button>
                <span className='mx-2'></span>
                <button type='submit' className='btn btn-success' onClick={(event) => changeSellerStatus('APPROVED')}>Approve</button>
              </div>
            </div>
          ) : ''
        }

        {
          sellerInfo.status === 'REVOKED' ? (
            <div className='row form-group'>
              <div className='col-sm-4'></div>
              <div className='col-sm-8 text-center'>
                <button type='submit' className='btn btn-secondary btn-block' onClick={(event) => changeSellerStatus('APPROVED')}>Activate</button>
              </div>
            </div>
          ) : ''
        }
  
        {
          sellerInfo.status === 'APPROVED' ? (
            <div className='row form-group'>
              <div className='col-sm-4'></div>
              <div className='col-sm-8 text-center'>
                <button type='reset' className='btn btn-danger' onClick={(event) => changeSellerStatus('REVOKED')}>Revoke</button>
                <span className='mx-2'></span>
                <button type='submit' className='btn btn-primary' onClick={(event) => updateSellerInfo(event)}>Update</button>
              </div>
            </div>
          ) : ''
        }
      </React.Fragment>
    )
  }

  
}

export default SellerInfo;