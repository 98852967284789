import { useReducer } from "react";
import _ from 'lodash';
import algoliasearch from 'algoliasearch';
import { createNullCache } from '@algolia/cache-common';
import LogSearchContext from "./logSearchContext";
import LogSearchReducer from "./logSearchReducer";
import { getCurrentUser } from '../../helpers/getCurrentUser';
import { RESET_CONTEXT, SET_SEARCH_PARAMS, SET_SEARCH_RESULT, SET_LOADING } from "../types";

const client = algoliasearch(process.env.REACT_APP_ALGOLIAAPPID, process.env.REACT_APP_ALGOLIAPUBKEY, {
  responsesCache: createNullCache(),
  requestsCache: createNullCache()
});
const LOGINDEX = 'stg_logs';

const LogSearchState = (props) => {
  const initialState = {
    searchResult: {},
    searchParams: {
      query: '',
      page: 0,
      filters: {
        status: []
      },
      pageSize: 20
    },
    loading: true
  };

  const [state, dispatch] = useReducer(LogSearchReducer, initialState);

  const clear = () => {
    dispatch({ type: RESET_CONTEXT, payload: initialState });
  }

  //Do Search
  const doSearch = async ({ query, filters, page, pageSize }) => {
    dispatch({ type: SET_LOADING, payload: true });
    const user = await getCurrentUser();

    const searchQuery = {
      indexName: LOGINDEX,
      query,
      params: {
        page: page,
        hitsPerPage: pageSize
      }
    }

    searchQuery.params.filters = `api:${filters.api[0]}`

    const result = await client.multipleQueries([searchQuery], { headers: { 'X-Algolia-UserToken': user.uid } });
    const queryResult = result.results[0];

    dispatch({ type: SET_LOADING, payload: false });

    dispatch({ type: SET_SEARCH_PARAMS, payload: { query, filters, page, pageSize } });
    dispatch({ type: SET_SEARCH_RESULT, payload: queryResult });
    return;
  }

  //Set loading
  const setLoading = (status) => {
    return dispatch({ type: SET_LOADING, payload: status });
  };


  const filterExist = (filter, filterId) => {
    const filterIndex = state.searchParams.filters[filter].indexOf(filterId);
    if (filterIndex > -1) {
      return true;
    } else {
      return false;
    }
  }

  const getFilterIndex = (filter, filterId) => {
    const filterIndex = state.searchParams.filters[filter].indexOf(filterId);
    if (filterIndex > -1) {
      return filterIndex;
    } else {
      return filterIndex;
    }
  }

  const clearFilter = async () => {
    state.searchParams.filters = {
      page: 0,
      status: []
    };
    await doSearch(state.searchParams);
  }

  return (
    <LogSearchContext.Provider
      value={{
        searchParams: state.searchParams,
        searchResult: state.searchResult,
        loading: state.loading,
        filterExist,
        getFilterIndex,
        doSearch,
        clearFilter,
        setLoading,
        clear
      }}
    >
      {props.children}
    </LogSearchContext.Provider>
  );
};

export default LogSearchState;
