import { useReducer } from "react"
import algoliasearch from 'algoliasearch'
import { createNullCache } from '@algolia/cache-common'
import OrderSearchContext from './orderSearchContext'
import OrderSearchReducer from './orderSearchReducer'
import { getCurrentUser } from '../../helpers/getCurrentUser'
import { RESET_CONTEXT, SET_SEARCH_PARAMS, SET_SEARCH_RESULT, SET_LOADING } from '../types'

const client = algoliasearch(process.env.REACT_APP_ALGOLIAAPPID, process.env.REACT_APP_ALGOLIAPUBKEY, {
  responsesCache: createNullCache(),
  requestsCache: createNullCache()
})

const ORDERSINDEX = process.env.REACT_APP_ORDERINDEX;

const OrderSearchState = (props) => {
  const initialState = {
    searchResult: {},
    searchParams: {
      query: '',
      page: 0,
      filters: {
        poStatus: []
      },
      pageSize: 20
    },
    loading: true
  }

  const [state, dispatch] = useReducer(OrderSearchReducer, initialState)

  const clear = () => {
    dispatch({ type: RESET_CONTEXT, payload: initialState})
  }

  const orderSearch = async ({query, filters, page, pageSize}) => {
    dispatch({ type: SET_LOADING, payload: true})
    const user = await getCurrentUser()

    const searchQuery = {
      indexName: ORDERSINDEX,
      query,
      params: {
        page: page,
        hitsPerPage: pageSize
      },
      attributesToHighlight: [
        'buyer.companyName',
        'buyer.userName',
        'orderNumber'
      ]
    }

    // searchQuery.params.filters = `poStatus:${filters.poStatus[0]}`

    const result = await client.multipleQueries([searchQuery], { headers: { 'X-Algolia-UserToken': user.uid } })
    const queryResult = result.results[0]

    dispatch({ type: SET_LOADING, payload: false})
    dispatch({ type: SET_SEARCH_PARAMS, payload: {query, filters, page, pageSize}})
    dispatch({ type: SET_SEARCH_RESULT, payload: queryResult})

    return;
  }

  //Set loading
  const setLoading = (status) => {
    return dispatch({ type: SET_LOADING, payload: status})
  }

  const filterExist = (filter, filterId) => {
    const filterIndex = state.searchParams.filters[filter].indexOf(filterId);
    if( filterIndex > -1 ) {
      return true;
    } else {
      return false;
    }
  }

  const getFilterIndex = (filter, filterId) => {
    const filterIndex = state.searchParams.filters[filter].indexOf(filterId);
    if( filterIndex > -1 ) {
      return filterIndex;
    } else {
      return filterIndex;
    }
  }

  const clearFilter = async () => {
    state.searchParams.filters = {
      page: 0,
      role: []
    };
    await orderSearch(state.searchParams);
  }

  return (
    <OrderSearchContext.Provider
      value={{
        searchParams: state.searchParams,
        searchResult: state.searchResult,
        loading: state.loading,
        filterExist,
        getFilterIndex,
        orderSearch,
        clearFilter,
        setLoading,
        clear
      }}
    >
      {props.children}
    </OrderSearchContext.Provider>
  );
}

export default OrderSearchState;