import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import confirm from "reactstrap-confirm";
import { useHistory } from "react-router-dom";
import _ from 'lodash';

import makeAPIRequest from '../../helpers/makeAPIRequest';
import showNotification from '../../helpers/showNotification';

import Spinner from '../layouts/Spinner';

const MARKETPLACE_API_URL = process.env.REACT_APP_MARKETPLACE_API_URL;

const ProductUpdate = () => {
  const { productId } = useParams();

  const history = useHistory();

  const [categories, setCategories] = useState([]);
  const [productUpdate, setProductUpdate] = useState(null);
  const [currentBlock, setCurrentBlock] = useState('');
  const [updateBlock, setUpdateBlock] = useState('');
  const [showSpinner, setShowSpinner] = useState(true);

  const field = {
    chapterId: {
      label: 'ATA Chapter',
      id: 'chapterId',
      required: true
    },
    name: {
      label: 'Name',
      id: 'name',
      required: true
    },
    description: {
      label: 'Description',
      id: 'description',
      required: false
    },
    currency: {
      label: 'Currency',
      id: 'currency',
      required: true
    },
    price: {
      label: 'Price',
      id: 'price',
      required: true
    },
    stock: {
      label: 'Stock Quantity',
      id: 'stock',
      required: true
    },
    partNo: {
      label: 'Part No',
      id: 'partNo',
      required: true
    },
    // partId: {
    //   label: 'Part ID',
    //   id: 'partId',
    //   required: false
    // },
    serialNo: {
      label: 'Serial No',
      id: 'serialNo',
      required: false
    },
    batchNo: {
      label: 'Batch No',
      id: 'batchNo',
      required: false
    },
    condition: {
      label: 'Condition',
      id: 'condition',
      required: true
    },
    location: {
      label: 'Location',
      id: 'location',
      required: true
    },
    manufacturer: {
      label: 'Manufacturer',
      id: 'manufacturer',
      required: true
    },
    manufacturerCode: {
      label: 'Manufacturer Code',
      id: 'manufacturerCode',
      required: true
    },
    materialClass: {
      label: 'Material Class',
      id: 'materialClass',
      required: true
    },
    images: {
      label: 'Images',
      id: 'images',
      required: true
    },
    documents: {
      label: 'Documents',
      id: 'documents',
      required: true
    },
    documentTypes: {
      label: 'Document Types',
      id: 'documentTypes',
      required: false
    },
    naas: {
      label: 'National Aviation Authority (NAA)',
      id: 'naas',
      required: false
    },
    warranty: {
      label: 'Warranty',
      id: 'warranty',
      required: true
    },
    warrantyTime: {
      label: 'Warranty Time',
      id: 'warrantyTime',
      required: true
    },
    uom: {
      label: 'Unit Of Measure',
      id: 'uom',
      required: true
    },
    tsn: {
      label: 'Time Since New (Flight Hours)',
      id: 'tsn',
      required: false
    },
    csn: {
      label: 'Cycle Since New (Flight Cycle)',
      id: 'csn',
      required: false
    },
    tso: {
      label: 'Time Since Overhaul (Flight Hours)',
      id: 'tso',
      required: false
    },
    cso: {
      label: 'Cycle Since Overhaul (Flight Cycle)',
      id: 'cso',
      required: false
    },
    tsr: {
      label: 'Time Since Repaired (Flight Hours)',
      id: 'tsr',
      required: false
    },
    csr: {
      label: 'Cycle Since Repaired (Flight Cycle)',
      id: 'csr',
      required: false
    },
    status: {
      label: 'Status',
      id: 'status',
      required: false
    }
  }

  const getCategories = async () => {
    const result = await makeAPIRequest('get', 'category/catalog', {}, MARKETPLACE_API_URL);
    setCategories(result);
  }

  const getProductUpdate = async () => {
    const result = await makeAPIRequest('get', `products/update/${productId}`, {});
    if(_.get(result, 'error', false)) {
      return showNotification("danger", "Error", [result.message]);
    }

    const diffCurrent = [];
    const diffUpdate = [];

    Object.keys(result.diff).map(fieldId => {

      const value = result.diff[fieldId];
      console.log(value);
      switch(fieldId) {
        case 'images':
          diffCurrent.push( 
            <div className='row form-group'>
              <label htmlFor='companyName' className='col-sm-4 col-form-label input-label'>{field[fieldId].label}</label>
              <div className='col-sm-8'>
                <div className='input-group'>
                  <ul className='list-group w-100 mt-2'> {
                    value.current !== null ?
                      value.current.map((file, index)=> {
                        return (
                          <li className='list-group-item' key={index}>
                            <a target='_blank' rel="noreferrer" href={file.url}><img src={file.url} width='75px' alt="" /> {file.name}</a>
                          </li>
                        )
                      }) : "None"
                  }
                  </ul> 
                </div>
              </div>
            </div>
          )
          diffUpdate.push(
            <div className='row form-group'>
              <label htmlFor='companyName' className='col-sm-4 col-form-label input-label'>{field[fieldId].label}</label>
              <div className='col-sm-8'>
                <div className='input-group'>
                  <ul className='list-group w-100 mt-2'> {
                    value.update.map((file, index)=> {
                      return (
                        <li className='list-group-item' key={index}>
                          <a target='_blank' rel="noreferrer" href={file.url}><img src={file.url} alt="" width='75px' /> {file.name}</a>
                        </li>
                      )
                    }) 
                  }
                  </ul> 
                </div>
              </div>
            </div>
          )
          break;
          case 'documents':
            value.update.length > 0 ?
              diffCurrent.push(
                <div className='row form-group'>
                  <label htmlFor='companyName' className='col-sm-4 col-form-label input-label'>{field[fieldId].label}</label>
                  <div className='col-sm-8'>
                    <div className='input-group'>
                      <ul className='list-group w-100 mt-2'> {
                        value.current !== null ?
                          value.current.map((file, index)=> {
                            return (
                              <li className='list-group-item' key={index}>
                                <a target='_blank' rel="noreferrer" href={file.url}>{file.name}</a>
                              </li>
                            )
                          }) : "None"
                      }
                      </ul> 
                    </div>
                  </div>
                </div>
              ) : <div />

            value.update.length > 0 ?
              diffUpdate.push(
                <div className='row form-group'>
                  <label htmlFor='companyName' className='col-sm-4 col-form-label input-label'>{field[fieldId].label}</label>
                  <div className='col-sm-8'>
                    <div className='input-group'>
                      <ul className='list-group w-100 mt-2'> {
                        value.update.map((file, index)=> {
                          return (
                            <li className='list-group-item' key={index}>
                              <a target='_blank' rel="noreferrer" href={file.url}>{file.name}</a>
                            </li>
                          )
                        })
                      }
                      </ul> 
                    </div>
                  </div>
                </div>
              ) : <div/>
            break;
        default:
          diffCurrent.push(
            <div className='row form-group'>
              <label htmlFor='companyName' className='col-sm-4 col-form-label input-label'>{field[fieldId].label}</label>
              <div className='col-sm-8'>
                {_.isArray(value.current) ? value.current.map((item) => (
                  <div className='input-group'>
                    <input type='text' className='form-control' id={fieldId} placeholder={field[fieldId].label} readOnly value={item || item===0 ? item : ''}/>  
                  </div>
                )): (
                  <div className='input-group'>
                    <input type='text' className='form-control' id={fieldId} placeholder={field[fieldId].label} readOnly value={value.current || value.current===0 ? value.current : ''}/>  
                  </div>
                )}
              </div>
            </div>
          )

          diffUpdate.push(
            <div className='row form-group'>
              <label htmlFor='companyName' className='col-sm-4 col-form-label input-label'>{field[fieldId].label}</label>
              <div className='col-sm-8'>
                {_.isArray(value.current) ? value.update.map((item) => (
                  <div className='input-group'>
                    <input type='text' className='form-control' id={fieldId} placeholder={field[fieldId].label} readOnly value={item}/>  
                  </div>
                )): (
                  <div className='input-group'>
                    <input type='text' className='form-control' id={fieldId} placeholder={field[fieldId].label} readOnly value={value.update}/>  
                  </div>
                )}
              </div>
            </div>
          )
      }

      
    })

    setCurrentBlock(diffCurrent);
    setUpdateBlock(diffUpdate);
    setProductUpdate(result);
    setShowSpinner(false)
  };

  
  const productUpdateStatus = async (status) => {
    const confirmed = await confirm({
      title: 'Please confirm',
      message: (`Are you sure you want to change status to ${status}?`),
      confirmText: "Confirm",
      confirmColor: "secondary",
      cancelColor: "link text-danger"
    });

    if(confirmed) {
      setShowSpinner(true);
      const profileUpdateResult = await makeAPIRequest('put', `products/update/${productId}`, {status, id: productUpdate.id});
      setShowSpinner(false);
      history.goBack();
    }
  }

  useEffect(() => {
    getCategories();
    getProductUpdate();
  }, [])
  if(showSpinner) {
    return <Spinner />
  } else {
    return (
      <div id="productUpdateContainer">
        <h3>Product Update</h3>
        <br/>
        {
          productUpdate ? (
            <div className='row'>
              <div className='col'>
                <div className='p-4 border bg-light mb-3'>
                  <h5>Current</h5>
                  <hr/>
                  {currentBlock}
                </div>
              </div>
              <div className='col'>
                <div className='p-4 border bg-light mb-3'>
                  <h5>Update</h5>
                  <hr/>
                  {updateBlock}
                </div>
              </div>
            </div>
            
          ) : <Spinner />
        }
        <div className='row form-group'>
          <div className='col text-center'>
            <button type='reset' className='btn btn-danger' onClick={(event) => productUpdateStatus('DECLINED')}>Decline</button>
            <span className='mx-2'></span>
            <button type='submit' className='btn btn-primary' onClick={(event) => productUpdateStatus('APPROVED')}>Approve</button>
          </div>
        </div>
      </div>
    )
  }
  
}

export default ProductUpdate;