const productStatus =  {
    ACTIVE: "ACTIVE",
    SOLD_OUT: "SOLD OUT",
    DELISTED: "DELISTED",
    DECLINED: "DECLINED",
    OWNED: "OWNED",
    PENDING_APPROVAL:"PENDING APPROVAL",
    PENDING_UPDATE: "PENDING UPDATE",
    ORDER_PLACED: "ORDER PLACED"
  };
  
  module.exports = productStatus
  