import React, {useState, useEffect} from 'react';
import confirm from "reactstrap-confirm";

import makeAPIRequest from '../../helpers/makeAPIRequest';
import showNotification from '../../helpers/showNotification';

const CreditTermInfo = ({userId, ctInfo, getUserDetails }) => {
 

  const [creditTermInfo, setCreditTermInfo]= useState(ctInfo);

  const fieldNames = {
    ctCompanyName: 'Company Name',
    ctCompanyRegNumber: 'Registration Number',
    ctCompanyAddress: 'Company Address',
    creditTermYear: 'Credit Term Options'
  }

  const changeCreditTermStatus = async (ctStatus) => {
    if( validateCreditTermInfo() ) {
      const confirmed = await confirm({
        title: 'Please confirm',
        message: (`Are you sure you want to change Credit Term Application status?`),
        confirmText: "Confirm",
        confirmColor: "secondary",
        cancelColor: "link text-danger"
      });

      if(confirmed) {
        const result = await makeAPIRequest('put', `users/members/creditterm/${userId}/status`,{...creditTermInfo, ctStatus});
        showNotification('success', 'Credit Term', ['Change status is successfull.']);
        setCreditTermInfo({
          ...creditTermInfo,
          ctStatus
        });
      }
    }
  }

  const updateCreditTermInfo = async () => {
    if( validateCreditTermInfo() ) {
      const result = await makeAPIRequest('put', `users/members/creditterm/${userId}/update`, creditTermInfo);
      showNotification('success', 'Credit Term', ['Update info is successfull.']);
      setCreditTermInfo(creditTermInfo);
    }
  }

  const validateCreditTermInfo = () => {
    let errors = [];

    Object.keys(creditTermInfo).map((fieldId) => {
      const value = creditTermInfo[fieldId];

      if(!value) {
        errors.push(fieldNames[fieldId]+' is required.');
      }
      return (!value);
    })

    if(errors.length > 0) {
      showNotification("danger", "Please correct the following:", errors);
      return false;
    }
    
    return true;
  }

  const handleChange = (event) => {
    setCreditTermInfo({
      ...creditTermInfo,
      [event.target.id]: event.target.value
    });
  }

  useEffect(() => {
    getUserDetails();
  }, [creditTermInfo]);

  if(!creditTermInfo) {
    return (
      <React.Fragment>
        <h5>Credit Term Info</h5>
        <hr/>
        <p>Member has no application for Credit Term Payments.</p>
      </React.Fragment>  
    )
  } else {
    let pillType = '';
    let ctStatus = ''
    switch (creditTermInfo.ctStatus) {
      case 'DECLINED':
        pillType = 'danger';
        ctStatus = 'Declined';
        break;
      case 'APPROVED':
        pillType = 'success';
        ctStatus = 'Approved';
        break;
      default:
        pillType = 'warning';
        ctStatus = 'Pending'
    }

    return (
      <React.Fragment>
        {
          <span className={`badge badge-pill badge-${pillType} float-right`}>{ctStatus}</span>
        }
        <h5>Credit Term Info</h5>
        
        <div className='row form-group'>
          <label htmlFor='ctCompanyName' className='col-sm-4 col-form-label input-label'>{fieldNames.ctCompanyName}</label>
          <div className='col-sm-8'>
            <div className='input-group'>
              <input type='text' className='form-control' id='ctCompanyName' placeholder={fieldNames.ctCompanyName} required value={creditTermInfo.ctCompanyName} onChange={handleChange}/>  
            </div>
          </div>
        </div>
  
        <div className='row form-group'>
          <label htmlFor='ctCompanyRegNumber' className='col-sm-4 col-form-label input-label'>{fieldNames.ctCompanyRegNumber}</label>
          <div className='col-sm-8'>
            <div className='input-group'>
              <input type='text' className='form-control' id='ctCompanyRegNumber' placeholder={fieldNames.ctCompanyRegNumber} required value={creditTermInfo.ctCompanyRegNumber} onChange={handleChange}/>  
            </div>
          </div>
        </div>
  
        <div className='row form-group'>
          <label htmlFor='ctCompanyAddress' className='col-sm-4 col-form-label input-label'>{fieldNames.ctCompanyAddress}</label>
          <div className='col-sm-8'>
            <div className='input-group'>
              <textarea rows='4' cols='50' className='form-control' id='ctCompanyAddress' placeholder={fieldNames.ctCompanyAddress} onChange={handleChange} defaultValue={creditTermInfo.ctCompanyAddress}></textarea>
            </div>
          </div>
        </div>
  
        <div className='row form-group'>
          <label htmlFor='creditTermYear' className='col-sm-4 col-form-label input-label'>{fieldNames.creditTermYear}</label>
          <div className='col-sm-8'>
            <div className='input-group'>
              <select className='form-control' id='creditTermYear' onChange={handleChange} defaultValue={creditTermInfo.creditTermYear}>
                <option value='15'>15</option>
                <option value='30'>30</option>
                <option value='45'>45</option>
              </select>
            </div>
          </div>
        </div>
        {
          creditTermInfo.ctStatus === 'PENDING APPROVAL' ? (
            <div className='row form-group'>
              <div className='col-sm-4'></div>
              <div className='col-sm-8 text-center'>
                <button type='reset' className='btn btn-danger' onClick={(event) => changeCreditTermStatus('DECLINED')}>Decline</button>
                <span className='mx-2'></span>
                <button type='submit' className='btn btn-success' onClick={(event) => changeCreditTermStatus('APPROVED')}>Approve</button>
              </div>
            </div>
          ) : ''
        }
  
        {
          creditTermInfo.ctStatus === 'APPROVED' ? (
            <div className='row form-group'>
              <div className='col-sm-4'></div>
              <div className='col-sm-8 text-center'>
                <button type='reset' className='btn btn-primary btn-block' onClick={(event) => updateCreditTermInfo()}>Update</button>
              </div>
            </div>
          ) : ''
        }
      </React.Fragment>
    )
  }
 
 
}

export default CreditTermInfo;