import { orderStatusColors as orderStatuses } from "../constant/orderStatus"
import { upperCase } from "lodash"

const setStatusStyle = (status) => {
  return {
    borderColor: orderStatuses[upperCase(status).replace(/ /g, '_')],
    color:  orderStatuses[upperCase(status).replace(/ /g, '_')],
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: 5,
    padding: 5,
    width: '100%',
    textAlign: 'center'
  }
}

export default setStatusStyle