import React, { Fragment } from 'react';
import _ from 'lodash';
import priceFormatter from "../../helpers/priceFormatter"

const defaultCurrency = process.env.REACT_APP_DEFAULT_CURRENCY

const ProductInfo = ({product}) => {
  const category = product.chapter.category;
  const chapter = product.chapter.chapter;
  return (
    <Fragment>
      <div className='row border-top border-left border-right py-3 mr-1'>
        <div className='col-lg-3'>Name</div>
        <div className='col-lg-9'>{product.name}</div>
      </div>
      <div className='row border-top border-left border-right py-3 mr-1'>
        <div className='col-lg-3'>Price</div>
        <div className='col-lg-9'><span className='h5 text-primary'>{product.currency ? product.currency : defaultCurrency} {priceFormatter(product.price, product.currency ? product.currency : defaultCurrency)}</span></div>
      </div>
      <div className='row border-top border-left border-right py-3 mr-1'>
        <div className='col'>
          {
            product.partNo && product.partNo !== '' ? (
              <div className='row'>
                <div className='col-lg-3'>Part No</div>
                <div className='col-lg-9'>{product.partNo}</div>
              </div>
            ) : ('')
          }
          {
            product.serialNo && product.serialNo !== '' ? (
              <div className='row'>
                <div className='col-lg-3'>Serial No</div>
                <div className='col-lg-9'>{product.serialNo}</div>
              </div>
            ) : ('')
          }
          {
            product.serialNo && product.serialNo !== '' ? (
              <div className='row '>
                <div className='col-lg-3'>Batch No</div>
                <div className='col-lg-9'>{product.batchNo}</div>
              </div>  
            ) : ('')
          }    
        </div>
      </div>
      <div className='row border-top border-left border-right py-3 mr-1'>
        <div className='col-lg-3'>Condition</div>
        <div className='col-lg-9'>{product.condition}</div>
      </div>
      <div className='row border-top border-left border-right py-3 mr-1'>
        <div className='col-lg-3'>Location</div>
        <div className='col-lg-9'>{product.location}</div>
      </div>
      <div className='row border-top border-left border-right py-3 mr-1'>
        <div className='col-lg-3'>Manufacturer</div>
        <div className='col-lg-9'>
          {product.manufacturer}<br/>
          <small>{category.category} - {chapter.ataChapterName}</small>
        </div>
      </div>
      {
        _.get(product, 'materialClass', false) ? (
          <div className='row border-top border-left border-right py-3 mr-1'>
            <div className='col-lg-3'>Material Class</div>
            <div className='col-lg-9'>{product.materialClass}</div>
          </div>
        ) : ''
      }
      <div className='row border-top border-left border-right py-3 mr-1'>
        <div className='col-lg-3'>Unit of Measure</div>
        <div className='col-lg-9'>{product.uom}</div>
      </div>
      <div className='row border-top border-bottom border-left border-right py-3 mr-1'>
        <div className='col-lg-3'>Sold By</div>
        <div className='col-lg-9'>{product.createdBy.companyName}</div>
      </div>
      {
        _.get(product, 'owner', false) ? (
          <div className='row border-top border-bottom border-left border-right py-3 mr-1'>
            <div className='col-lg-3'>Owner</div>
            <div className='col-lg-9'>{product.owner}</div>
          </div>
        ) : ''
      }
    </Fragment>
  )
}

export default ProductInfo;