import React from 'react';
import _ from 'lodash';
import { useHistory } from "react-router-dom";
import { upperCase, upperFirst } from 'lodash';
import DefaultImg from "../../images/ade-default-product.png"
import priceFormatter from "../../helpers/priceFormatter"
import trucksvg from "../../images/icons/truck.svg"
import toShortDateString from '../../helpers/toShortDateString'
import toShortDateTimeString from '../../helpers/toShortDateTimeString'
import { orderStatuses } from "../../constant/orderStatus"
import { orderUrgencyColor } from '../../constant/orderUrgency';
import "./orders.css"

const defaultCurrency = process.env.REACT_APP_DEFAULT_CURRENCY


const OrderList = ({ orders }) => {
  const history = useHistory();
  
  const handleViewOrder = (orderId, order) => {
    history.push(`/admin/orders/${orderId}`, {
      orderDetails: order
    });
  }

  return (
    orders.map((order, index) => {
      return (
        <div className='rowitem' key={order.objectID}>
          <div className='row rowfilterby'>
            <div className='col-6 ordercolitems' style={{ paddingTop: "10px" }}>
              <div>
                <b>Ordered By :</b> {order.buyer.companyName}
                <span className='companyCategory' style={{ marginLeft: "4px" }}>
                  {order.buyer.companyCategory}
                </span>
              </div>
              <div style={{ paddingTop: "6px" }}>
                <b>Placed By :</b> {order.buyer.userName}
              </div>
              <div style={{ paddingTop: "6px" }}>
                <b>Sold By :</b> {order.seller.companyName}
                <span className='companyCategory' style={{ marginLeft: "4px" }}>
                  {order.seller.companyCategory}
                </span>
              </div>
            </div>
            <div className='col-4 ordercolitems text-right'>
              <span style={{ fontSize: "medium", fontWeight: "bold" }}>Order ID: {order.orderNumber}</span><br />
            PO Number: {order.internalPO}<br />
              <span style={{ fontWeight: "bold" }}>Order Date: {toShortDateString(order.createdAt)}</span>
            </div>
            <div className='col-2 ordercolitems text-right'>
              <div className='orderPackageStatus' style={{ marginBottom: "5px", color: orderUrgencyColor[_.upperCase(order.orderUrgency).replace(/ /g, '_')] }}>
                Urgency: {order.orderUrgency}
              </div>
              <span className='orderPackageStatus' style={{
                color: orderStatuses[upperCase(order.poStatus).replace(/ /g, '_')]
              }}>
                {order.poStatus}
              </span>
            </div>
          </div>
          <hr className='rowhr' />
          {
            order.packages.slice(0, 3).map((orderPackage) => {
              return (
                orderPackage.products.map((product, key) => {
                  return (
                    <div key={key}>
                      <div className='row' style={{paddingBottom: "10px"}}>
                        <div className='col' style={{ textAlign: "left", paddingLeft: "25px" }}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" className="mr-1 mb-1 bi bi-box-seam" viewBox="0 0 16 16">
                            <path d="M8.186 1.113a.5.5 0 0 0-.372 0L1.846 3.5l2.404.961L10.404 2l-2.218-.887zm3.564 1.426L5.596 5 8 5.961 14.154 3.5l-2.404-.961zm3.25 1.7l-6.5 2.6v7.922l6.5-2.6V4.24zM7.5 14.762V6.838L1 4.239v7.923l6.5 2.6zM7.443.184a1.5 1.5 0 0 1 1.114 0l7.129 2.852A.5.5 0 0 1 16 3.5v8.662a1 1 0 0 1-.629.928l-7.185 2.874a.5.5 0 0 1-.372 0L.63 13.09a1 1 0 0 1-.63-.928V3.5a.5.5 0 0 1 .314-.464L7.443.184z" />
                          </svg>
                          <b>{orderPackage.packageId}</b>
                        </div>
                      </div>
                      <div className='row' style={{ paddingLeft: "20px" }}>
                        <div className='col-2 text-center'>
                          {
                            (product.images && product.images.length > 0) ? (
                              <img src={product.images[0].url} alt={product.name} width='75px' height="75px" />
                            ) : <img src={DefaultImg} alt={product.name} width='75px' height="75px" />
                          }
                        </div>
                        <div className='col-2'>
                          <b>{product.name}</b>
                          {product.partNo && product.partNo !== '' && (
                            <div>
                              PN: {product.partNo}
                            </div>
                          )}
                          {product.serialNo && product.serialNo !== '' && (
                            <div>
                              SN: {product.serialNo}
                            </div>
                          )}
                          {product.batchNo && product.batchNo !== '' && (
                            <div>
                              BN: {product.batchNo}
                            </div>
                          )}
                        </div>
                        <div className='col-2 text-center'>
                          {product.currency ? product.currency : defaultCurrency} {priceFormatter(product.price, product.currency ? product.currency : defaultCurrency)}
                        </div>
                        <div className='col-1 text-center'>
                          Qty: {product.quantity}
                        </div>

                        <div className='col-2 text-center'>
                          <div style={{ opacity: 0.9, fontSize: "small" }}>
                            <img width='16px' height='16px' src={trucksvg} style={{ marginRight: "4px" }} alt="Truck Img" />
                            {upperFirst(order.shipmentArrangement)} Arranged </div>
                        </div>
                        <div className='col-3'>
                          <span className='orderPackageStatus' style={{
                            color: orderStatuses[upperCase(orderPackage.status).replace(/ /g, '_')]
                          }}>
                            {orderPackage.status}
                          </span>
                        </div>
                      </div>
                      <hr className='rowhr' />
                    </div>
                  )
                })
              )
            })
          }
          <div className="row rowfilterby" style={{ paddingBottom: "5px" }}>
            <div className={order.packages.some(x => x.status === "Pending Seller Acceptance") ? "col-10 p-3 align-self-center text-right slaRow"
              : order.packages.length > 3 ? "col-10 p-3 align-self-center text-right moreItemsRow" : "col-10 p-3 align-self-center text-right"}>
              {order.packages.length > 3 ?
                <span> Showing 3 out of {order.packages.length} items. Click
                  <span style={{ fontWeight: "bold" }}> View Order</span> to see the rest.</span>
                : ""}
              {order.packages.length > 3 && order.packages.some(x => x.status === "Pending Seller Acceptance") ? <br /> : ""}
              {order.packages.some(x => x.status === "Pending Seller Acceptance") ?
                <span> Seller need to Accept this order by
                  <span style={{ fontWeight: "bold" }}> {order.orderDueDate ? toShortDateTimeString(order.orderDueDate) : ""}</span> T&C applies.</span>
                : ""}
            </div>
            <div className={order.packages.some(x => x.status === "Pending Seller Acceptance") ? "col-2 p-2 slaRow"
              : order.packages.length > 3 ? "col-2 p-2 moreItemsRow" : "col-2 p-2"}>
              <button type='button' className='btn btn-primary' style={{ height: "100%", width: "100%" }} onClick={(event) => handleViewOrder(order.objectID, order)}>View Order</button>
            </div>
          </div>
        </div>
      )
    })
  )
}

export default OrderList;
