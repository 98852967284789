import React, {useContext} from 'react';

import UserFilter from '../UserFilter';
import Listing from './Listing';
import SearchPagination from "../../searchpagination/SearchPagination";
import NoResult from "../../noresult/NoResult";

import UserSearchContext from "../../../context/userSearch/userSearchContext";

const Sellers = () => {
  const userSearchContext = useContext(UserSearchContext);
  const searchParams = userSearchContext.searchParams;
  const searchResult = userSearchContext.searchResult;
  
  const showPagination = typeof searchResult.nbPages != 'undefined' && searchResult.nbPages > 1;
  let users = [];
  if(typeof searchResult.hits != 'undefined') users = searchResult.hits;
  
  const setSearchPage = async (newPage) => {  
    searchParams.page = newPage-1;
    await userSearchContext.doSearch(searchParams);
  }

  const setSearchPageSize = async (pageSize) => {  
    searchParams.page = 0;
    searchParams.pageSize = pageSize;
    await userSearchContext.doSearch(searchParams);
  }

  return (
    <div id="sellersContainer">
      <h3>Sellers</h3>
      <br/>
      <UserFilter role={'seller'}/>
      { users.length > 0 ? <Listing list={users} /> : <NoResult /> }
      { showPagination ? <SearchPagination
          page={searchResult.page ? searchResult.page+1 : 1}
          size={searchParams.pageSize}
          total={searchResult.nbHits ? searchResult.nbHits : 0}
          handleChange={setSearchPage}
          changeSize={setSearchPageSize} /> : '' }
    </div>
  )
}

export default Sellers;