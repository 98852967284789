import React, {useState} from 'react'

const Listing = ({ list }) => {
  const [ query, setQuery ] = useState()

  const handleFilter = (e) => {
    const filter = e.target.value
    setQuery(filter)
    const table = document.getElementById("wishlistTable")
    const tr = table.getElementsByTagName("tr")

    for (let i = 0; i < tr.length; i++) {
      
      if (tr[i].innerHTML.toUpperCase().search(filter.toUpperCase()) > -1) {
        tr[0].style.display = ""
        tr[i].style.display = ""
      } else {
        tr[0].style.display = ""
        tr[i].style.display = "none"
      }
    }
  }

  return (
    <div>
    <div className="col-auto">
      <label className="sr-only" htmlFor="inlineFormInputGroup">Search</label>
      <div className="input-group mb-2">
        <input type="text" className="form-control form-control-lg" id="searchbox" placeholder="search..." onChange={(e) => handleFilter(e)} value={query}/>
        <div className="input-group-append">
          <div className="input-group-text">
            &nbsp;
            <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-search" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" d="M10.442 10.442a1 1 0 0 1 1.415 0l3.85 3.85a1 1 0 0 1-1.414 1.415l-3.85-3.85a1 1 0 0 1 0-1.415z"></path>
              <path fillRule="evenodd" d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z"></path>
            </svg>
            &nbsp;
          </div>
        </div>
      </div>
    </div>
    <div className="content table-full-width table-responsive">
      <table className="table table-striped table-hover" id="wishlistTable">
        <thead>
          <tr className='text-center'>
            <th>Part No</th>
            <th>Name</th>
            <th>Manufacturer</th>
            <th>Quantity</th>
            <th>Likes</th>
          </tr>
        </thead>
        <tbody>
        {
            list.map((item, index) => {
              return (
                <tr key={index} className='text-center'>
                  <td>{item.partNo}</td>
                  <td>{item.name}<br />
                  <small>{item.category} - {item.chapter}</small></td>
                  <td>{item.manufacturer}</td>
                  <td>{item.quantity}</td>
                  <td>{item.likes}</td>
                </tr>
              )
            })
          }
        </tbody>
      </table>
      <div className="footer">
        <div className="stats"><i></i></div>
      </div>
    </div>
    </div>
  )
}

export default Listing