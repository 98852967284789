import React, { Fragment } from 'react';

const NotificationContent = ({contents}) => {
  const message = contents.map((content, index) => {
    return <span key={'content-'+index}>{content}<br/></span>;
  })
  return (
    <Fragment> { message } </Fragment>
  )
}

export default NotificationContent;