import React from 'react';
import { useHistory } from "react-router-dom";
import FeatherIcon from 'feather-icons-react';

import toDateString from '../../../helpers/toDateString';

const Listing = ({ list }) => {
  const history = useHistory();
  const viewDetail = (userId) => {
    history.push(`/admin/users/members/view/${userId}`);
  }
  return (
    <div className="content table-full-width table-responsive">
      <table className="table table-striped table-hover">
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Phone</th>
            <th>Joined</th>
            <th>Credit Term</th>
            <th>Seller Application</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {
            list.map((member) => {
              var ctStatus = '';
              var ctStatusText = '';
              switch(member.ctStatus) {
                case 'PENDING APPROVAL':
                  ctStatus = 'Pending Approval';
                  ctStatusText = 'text-warning';
                  break;
                case 'DECLINED':
                  ctStatus = 'Declined';
                  ctStatusText = 'text-danger';
                  break;
                case 'APPROVED':
                  ctStatus = 'Approved';
                  ctStatusText = 'text-success';
                  break;
                default:
                  ctStatus = '';
                  ctStatusText = '';
              }

              var sellerStatus = '';
              var sellerStatusText = '';
              switch(member.sellerStatus) {
                case 'PENDING APPROVAL':
                  sellerStatus = 'Pending Approval';
                  sellerStatusText = 'text-warning';
                  break;
                case 'DECLINED':
                  sellerStatus = 'Declined';
                  sellerStatusText = 'text-danger';
                  break;
                case 'APPROVED':
                  sellerStatus = 'Approved';
                  sellerStatusText = 'text-success';
                  break;
                default:
                  sellerStatus = '';
                  sellerStatusText = '';
              }

              return (
                <tr key={member.objectID} onClick={(event) => viewDetail(member.objectID)} role="button">
                  <td>{member.displayName}</td>
                  <td>{member.email}</td>
                  <td>{member.phoneNumber}</td>
                  <td>{toDateString(member.createdAt)}</td>
                  <td className={ctStatusText}>{ ctStatus }</td>
                  <td className={sellerStatusText}>{ sellerStatus }</td>
                  <td className="text-center">
                    {
                      member.ctStatus && member.ctStatus === 'PENDING APPROVAL' ? (
                        <FeatherIcon icon='credit-card'/>
                      ) : ''
                    }
                    {
                      member.sellerStatus && member.sellerStatus === 'PENDING APPROVAL' ? (
                        <FeatherIcon icon='briefcase'/>
                      ) : ''
                    }
                  </td>
                </tr>
              )
            })
          }
        </tbody>
      </table>
      <div className="footer">
        <div className="stats"><i></i></div>
      </div>
    </div>
  )
}

export default Listing;