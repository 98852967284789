import React, { useContext } from 'react';
import { Link, useHistory } from "react-router-dom";
import { Button } from 'reactstrap';
import AuthContext from "../../context/auth/authContext";

const Header = () => {
  const authContext = useContext(AuthContext);
  const history = useHistory();

  const handleLogout = async (e) => {
    e.preventDefault();
    try {
      await authContext.logout();
      history.push("/login");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <header className="navbar navbar-dark sticky-top bg-dark flex-md-nowrap p-0 shadow">
      <Link to='/' className="navbar-brand col-md-3 col-lg-2 me-0 px-3">ADE</Link>
      <button className="navbar-toggler position-absolute d-md-none collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>
      <ul className="navbar-nav px-3">
        <li className="nav-item text-nowrap">
          <Button outline style={{ color: 'white', borderStyle: 'none' }} onClick={handleLogout}>Sign out</Button>
        </li>
      </ul>
    </header>
  )
}

export default Header;