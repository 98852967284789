import React from "react";
import { BrowserRouter as Router } from "react-router-dom";

//States
import AuthState from "./context/auth/AuthState";

//Routes
import Routes from "./Routes";

//components
import ReactNotification from "react-notifications-component";

import 'bootstrap/dist/css/bootstrap.css';
import "react-notifications-component/dist/theme.css";
import './App.css';


function App() {
  return (
    <AuthState>
      <ReactNotification />
      <div className="App">
        <Router>
          <Routes />
        </Router>
      </div>
    </AuthState>
  );
}

export default App;
