import React, { useState, useContext } from 'react';
import { Link } from "react-router-dom";
import FeatherIcon from 'feather-icons-react';
import { Collapse } from 'reactstrap';

import UserSearchContext from "../../context/userSearch/userSearchContext";

const Nav = () => {
  const userSearchContext = useContext(UserSearchContext);
  const [activeLink, setActiveLink] = useState('dashboard');
  const [activeAccordion, setActiveAccordion] = useState('');

  const setActiveAccordionLink = (accordion) => {
    if (activeAccordion !== '' && accordion === activeAccordion) {
      setActiveAccordion('')
    } else {
      setActiveAccordion(accordion)
    }
  }

  const resetUserSearch = async (role) => {
    await userSearchContext.clear();
    userSearchContext.searchParams.query = '';
    userSearchContext.searchParams.filters.role = [role];
    await userSearchContext.doSearch(userSearchContext.searchParams);
  }

  return (
    <nav id="sidebarMenu" className="col-md-3 col-lg-2 d-md-block bg-light sidebar">
      <div className="position-sticky pt-3 nav-collapse subnav-collapse">
        <ul className="nav flex-column">
          <li className="nav-item">
            <Link to='/' className={'nav-link ' + (activeLink === 'dashboard' ? 'active' : '')} onClick={(event) => setActiveLink('dashboard')}>
              <FeatherIcon icon='home' />
              Dashboard
            </Link>
          </li>
          <li className="nav-item dropdown">
            <Link className="nav-link dropdown-toggle" onClick={(event) => setActiveAccordionLink('users')} href="#">
              <FeatherIcon icon='users' />
              Users
            </Link>
            <Collapse isOpen={activeAccordion === 'users'}>
              <Link to='/admin/users/members' className={'dropdown-item ' + (activeLink === 'members' ? 'active' : '')} onClick={(event) => { setActiveLink('members'); resetUserSearch('') }}>
                <FeatherIcon icon='shopping-cart' />
                Members
              </Link>
              <Link to='/admin/users/sellers' className={'dropdown-item ' + (activeLink === 'sellers' ? 'active' : '')} onClick={(event) => { setActiveLink('sellers'); resetUserSearch('seller') }}>
                <FeatherIcon icon='briefcase' />
                Sellers
              </Link>
            </Collapse>
          </li>


          {/* <li className="nav-item dropdown">
            <a className="nav-link dropdown-toggle" onClick={(event) => setActiveAccordionLink('products')} href="#">
              <FeatherIcon icon='box'/>
              Products
            </a>
            <Collapse isOpen={activeAccordion==='products'}>
              <Link to='/admin/products/status/pending' className={'dropdown-item ' + (activeLink === 'pendingproducts' ? 'active' : '')} onClick={(event) => {setActiveLink('pendingproducts'); resetProductSearch('pending')} }>
                <FeatherIcon icon='alert-triangle'/>
                Pending Approval
              </Link>
              <Link to='/admin/products/status/updating' className={'dropdown-item ' + (activeLink === 'pendingupdate' ? 'active' : '')} onClick={(event) => {setActiveLink('pendingupdate'); resetProductSearch('active', true)} }>
                <FeatherIcon icon='edit'/>
                Pending Update
              </Link>
              <Link to='/admin/products/status/active' className={'dropdown-item ' + (activeLink === 'activeproducts' ? 'active' : '')} onClick={(event) => {setActiveLink('activeproducts'); resetProductSearch('active', false)} }>
                <FeatherIcon icon='check-square'/>
                Active
              </Link>
              <Link to='/admin/products/status/declined' className={'dropdown-item ' + (activeLink === 'declinedproducts' ? 'active' : '')} onClick={(event) => {setActiveLink('declinedproducts'); resetProductSearch('declined')} }>
                <FeatherIcon icon='minus-square'/>
                Declined
              </Link>
              <Link to='/admin/products/status/delisted' className={'dropdown-item ' + (activeLink === 'delistedproducts' ? 'active' : '')} onClick={(event) => {setActiveLink('delistedproducts'); resetProductSearch('delisted')} }>
                <FeatherIcon icon='x-square'/>
                Delisted
              </Link>
              <Link to='/admin/products/wishlist' className={'dropdown-item '+(activeLink === 'wishlist' ? 'active' : '')} onClick={(event) => setActiveLink('wishlist')}>
                <FeatherIcon icon='heart'/>
                Wishlist
              </Link>
            </Collapse>
          </li> */}


          <li className="nav-item">
            <Link to='/admin/products/status/all' className={'nav-link ' + (activeLink === 'products' ? 'active' : '')} onClick={(event) => setActiveLink('products')}>
              <FeatherIcon icon='box' />
              Products
            </Link>
          </li>
          <li className="nav-item">
            <Link to='/admin/products/wishlist' className={'nav-link ' + (activeLink === 'wishlist' ? 'active' : '')} onClick={(event) => setActiveLink('wishlist')}>
              <FeatherIcon icon='heart' />
              Wishlists
            </Link>
          </li>

          <li className="nav-item">
            <Link to='/admin/orders/status/all' className={'nav-link ' + (activeLink === 'orders' ? 'active' : '')} onClick={(event) => setActiveLink('orders')}>
              <FeatherIcon icon='box' />
              Orders
            </Link>
          </li>
          <li className="nav-item dropdown">
            <a className="nav-link dropdown-toggle" onClick={(event) => setActiveAccordionLink('auditlog')} href="#">
              <FeatherIcon icon='activity' />
              Audit Log
            </a>
            <Collapse isOpen={activeAccordion === 'auditlog'}>
              { }
              <Link to='/auditlog/bclog' className={'dropdown-item ' + (activeLink === 'bclog' ? 'active' : '')} onClick={(event) => setActiveLink('bclog')}>
                <FeatherIcon icon='activity' />
                BC Log
              </Link>
            </Collapse>
          </li>
          <li className="nav-item dropdown">
            <Link className="nav-link dropdown-toggle" onClick={(event) => setActiveAccordionLink('profile')} href="#">
              <FeatherIcon icon='user' />
              Profile
            </Link>
            <Collapse isOpen={activeAccordion === 'profile'}>
              <Link to='/profile/update/password' className={'dropdown-item ' + (activeLink === 'password' ? 'active' : '')} onClick={(event) => setActiveLink('password')}>
                <FeatherIcon icon='lock' />
                Change Password
              </Link>
            </Collapse>
          </li>
        </ul>
      </div>
    </nav>
  )
}

export default Nav;