import React, { useContext, useEffect } from 'react';

import UserSearchContext from "../../context/userSearch/userSearchContext";

const UserFilter = ({role}) => {
  const userSearchContext = useContext(UserSearchContext);
  const searchParams = userSearchContext.searchParams;
  searchParams.filters.role = [role];
  const query = searchParams.query;
  
  const setSearchQuery = async (event) => {  
    searchParams.query = event.target.value;
    searchParams.page = 0;
    await userSearchContext.doSearch(searchParams);
  }

  const initSearch = async () => {
    await userSearchContext.doSearch(searchParams);
  }

  useEffect(() => {
    initSearch();
  }, []);

  return (
    <div className="col-auto">
      <label className="sr-only" htmlFor="inlineFormInputGroup">Search</label>
      <div className="input-group mb-2">
        <input type="text" className="form-control form-control-lg" id="searchbox" placeholder="search..." onChange={(e) => setSearchQuery(e)} value={query}/>
        <div className="input-group-append">
          <div className="input-group-text">
            &nbsp;
            <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-search" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" d="M10.442 10.442a1 1 0 0 1 1.415 0l3.85 3.85a1 1 0 0 1-1.414 1.415l-3.85-3.85a1 1 0 0 1 0-1.415z"></path>
              <path fillRule="evenodd" d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z"></path>
            </svg>
            &nbsp;
          </div>
        </div>
      </div>
    </div>
  ) 
}

export default UserFilter;