import React, { useRef } from 'react';
import ImageGallery from 'react-image-gallery';
import DefaultImg from '../../images/ade-default-product.png'
import "react-image-gallery/styles/css/image-gallery.css";

const ProductGallery = ({images}) => {
  const galleryRef = useRef({});
  let imageGallery

  if (images) {
    imageGallery = images.map((img) => {
      return {
        original: img.url,
        thumbnail: img.url,
      }
    });
  } else {
    imageGallery = [{
      original: DefaultImg,
      thumbnail: DefaultImg,
    }]
  }

  const handleClick = (event) => {
    const gallery = galleryRef.current;
    const imgIndex = gallery.getCurrentIndex();
    gallery.slideToIndex(imgIndex+1);
  }

  return (
    <ImageGallery items={imageGallery} showNav={false} showPlayButton={false} ref={galleryRef} onClick={(event) => handleClick(event)} />
  )
}

export default ProductGallery;