import React from 'react';
import _ from 'lodash';
import { useHistory } from "react-router-dom";
import priceFormatter from "../../helpers/priceFormatter"
import DefaultImg from "../../images/ade-default-product.png"
import productStatus from "../../constant/productStatus"

const defaultCurrency = process.env.REACT_APP_DEFAULT_CURRENCY

const ProductList = ({ products }) => {
  const history = useHistory();
  const viewDetail = (productId, index) => {
    if (products[index].status === productStatus.PENDING_UPDATE) {
      history.push(`/admin/products/update/${productId}`);
    } else {
      history.push(`/admin/products/view/${productId}`);
    }
    
  }
  return (
    <div className="content table-full-width table-responsive">
      <table className="table table-striped table-hover">
        <thead>
          <tr>
            <th></th>
            <th>Listed By</th>
            <th>Part No</th>
            <th>Serial No</th>
            <th>Batch No</th>
            <th>Name</th>
            <th>Price</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {
            products.map((product, index) => {
              return (
                <tr key={product.objectID} onClick={(event) => viewDetail(product.objectID, index)} role="button">
                  <td className="text-center">
                  {
                    (product.images && product.images.length > 0) ? (
                    <img src={product.images[0].url} alt={product.name} width='75px'/> 
                    ) : <img src={DefaultImg} alt={product.name} width='75px'/>
                  }
                  </td>
                  <td>
                    {product.createdByCompanyName}
                  </td>
                  <td>{product.partNo}</td>
                  <td>{product.serialNo ? product.serialNo : 'N/A'}</td>
                  <td>{product.batchNo ? product.batchNo : 'N/A'}</td>
                  <td>
                    {product.name}
                    <div className="font-italic text-secondary">{product.atachapterName} &gt; {product.category}</div>
                  </td>
                  <td>{product.currency ? product.currency : defaultCurrency} {priceFormatter(product.price, product.currency ? product.currency : defaultCurrency)}</td>
                  <td className="text-center"><span className='text-capitalize'>{product.status}</span></td>
                </tr>
              )
            })
          }
        </tbody>
      </table>
      <div className="footer">
        <div className="stats"><i></i></div>
      </div>
    </div>
  )
}

export default ProductList;