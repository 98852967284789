import React, {useState, useEffect} from 'react';
import _ from 'lodash';
import Avatar from 'react-avatar';
import confirm from "reactstrap-confirm";

import makeAPIRequest from '../../helpers/makeAPIRequest';
import showNotification from '../../helpers/showNotification';

import Spinner from '../layouts/Spinner';

const BasicInfo = ({userId, basicInfo, getUserDetails}) => {
  const api = _.get(basicInfo, 'role', null) === 'seller' ? 'sellers' : 'members';

  const userInfo = {
    displayName: _.get(basicInfo, 'displayName', null),
    userPhotoURL: _.get(basicInfo, 'userPhotoURL', ''),
    email: _.get(basicInfo, 'email', null),
    phoneNumber: _.get(basicInfo, 'phoneNumber', null),
    addressLine1: _.get(basicInfo, 'addressLine1', null),
    addressLine2: _.get(basicInfo, 'addressLine2', null),
    addressLine3: _.get(basicInfo, 'addressLine3', null),
    city: _.get(basicInfo, 'city', null),
    state: _.get(basicInfo, 'state', null),
    postCode: _.get(basicInfo, 'postCode', null),
    role: _.get(basicInfo, 'role', null),
    status: _.get(basicInfo, 'status', null)
  };
  const [ uploadedPhoto, setUploadedPhoto ] = useState(_.get(basicInfo, 'userPhotoURL', ''));
  const [user, setUserInfo]= useState(userInfo);
  const fieldNames = {
    displayName: {
      label: 'Full Name',
      required: true
    },
    email: {
      label: 'Email',
      required: true
    },
    phoneNumber: {
      label: 'Phone',
      required: true
    },
    addressLine1: {
      label: 'Address Line 1',
      required: true
    },
    addressLine2: {
      label: 'Address Line 2',
      required: false
    },
    addressLine3: {
      label: 'Address Line 3',
      required: false
    },
    city: {
      label: 'City',
      required: true
    },
    state: {
      label: 'State',
      required: true
    },
    postCode: {
      label: 'Postcode',
      required: true
    }
  }

  const updateBasicInfo = async () => {
    if( validateBasicInfo() ) {
      setUserInfo({
        ...user
      });
      const result = await makeAPIRequest('put', `users/${api}/basicinfo/${userId}/update`, user);
      console.log(result);
      if( !_.get(result, 'error', false) ) {
        showNotification('success', `${_.startCase(user.role)} Update`, [result.msg])
      }
      
    }
  }
  
  const changeUserStatus = async (status) => {
    const confirmed = await confirm({
      title: 'Please confirm',
      message: (`Are you sure you want to DEACTIVATE this user?`),
      confirmText: "Confirm",
      confirmColor: "secondary",
      cancelColor: "link text-danger"
    });

    if(confirmed) {
      const result = await makeAPIRequest('put', `users/${api}/deactivate/${userId}`,{});
      if( !_.get(result, 'error', false) ) {
        showNotification('success', `${_.startCase(user.role)} Update`, [result.msg])
        setUserInfo({
          ...user,
          status
        })
      }
    }
  }

  const validateBasicInfo = () => {
    let errors = [];

    Object.keys(fieldNames).map((fieldId) => {
      const value = user[fieldId];

      if(fieldNames[fieldId].required && !value) {
        errors.push(fieldNames[fieldId].label+' is required.');
      }
      return (!value);
    })

    if(errors.length > 0) {
      showNotification("danger", "Please correct the following:", errors);
      return false;
    }
    
    return true;
  }
  
  const handleChange = (event) => {
    setUserInfo({
      ...user,
      [event.target.id]: event.target.value
    });
  }

  useEffect(() => {
    getUserDetails();
    setUploadedPhoto(user.userPhotoURL)
  }, [user]);
  

  if( !user ) {
    return (
      <Spinner />  
    )
  } else {
    let pillType = '';
    let status = ''
    switch (user.status) {
      case 'INACTIVE':
        pillType = 'danger';
        status = 'Inactive';
        break;
      case 'ACTIVE':
        pillType = 'success';
        status = 'Active';
        break;
      default:
        pillType = 'warning';
        status = 'Pending'
    }
    return (
      <React.Fragment>
        {
          <span className={`badge badge-pill badge-${pillType} float-right`}>{status}</span>
        }
        <h5>Basic Info</h5>
        <div className='row form-group'>
          <label htmlFor='photoLabel' className='col-sm-4 col-form-label input-label'>Profile Photo</label>
          <div className='col-sm-8'>
            <div className='media align-items-center'>
            <label className='mr-4' htmlFor='avatarUploader'>
              {uploadedPhoto ? <Avatar className='avatar-img' round={true} src={uploadedPhoto}></Avatar> : 
                (user.displayName ? <Avatar name={user.displayName} className='avatar-img' round={true} /> : 
                  <Avatar name={user.email} className='avatar-img' round={true} />)
              }
            </label>
            </div>
          </div>
        </div>
        
        <div className='row form-group'>
          <label htmlFor='displayName' className='col-sm-4 col-form-label input-label'>{fieldNames.displayName.label}</label>
          <div className='col-sm-8'>
            <div className='input-group'>
              <input type='text' className='form-control' id='displayName' placeholder={fieldNames.displayName.label} required value={user.displayName} onChange={handleChange}/>  
            </div>
          </div>
        </div>

        <div className='row form-group'>
          <label htmlFor='email' className='col-sm-4 col-form-label input-label'>{fieldNames.email.label}</label>
          <div className='col-sm-8'>
            <div className='input-group'>
              <input 
                type='text'
                className='form-control' 
                id='email' 
                placeholder={fieldNames.email.label} 
                required 
                value={user.email} 
                // onChange={handleChange}
                disabled/>  
            </div>
          </div>
        </div>

        <div className='row form-group'>
          <label htmlFor='phoneNumber' className='col-sm-4 col-form-label input-label'>{fieldNames.phoneNumber.label}</label>
          <div className='col-sm-8'>
            <div className='input-group'>
              <input type='text' className='form-control' id='phoneNumber' placeholder={fieldNames.phoneNumber.label} required value={user.phoneNumber} onChange={handleChange}/>  
            </div>
          </div>
        </div>

        <div className='row form-group'>
          <label htmlFor='addressLine1' className='col-sm-4 col-form-label input-label'>{fieldNames.addressLine1.label}</label>
          <div className='col-sm-8'>
            <div className='input-group'>
              <input type='text' className='form-control' id='addressLine1' placeholder={fieldNames.addressLine1.label} required value={user.addressLine1} onChange={handleChange}/>  
            </div>
          </div>
        </div>

        <div className='row form-group'>
          <label htmlFor='addressLine2' className='col-sm-4 col-form-label input-label'>{fieldNames.addressLine2.label}</label>
          <div className='col-sm-8'>
            <div className='input-group'>
              <input type='text' className='form-control' id='addressLine2' placeholder={fieldNames.addressLine2.label} required value={user.addressLine2} onChange={handleChange}/>  
            </div>
          </div>
        </div>

        <div className='row form-group'>
          <label htmlFor='addressLine3' className='col-sm-4 col-form-label input-label'>{fieldNames.addressLine3.label}</label>
          <div className='col-sm-8'>
            <div className='input-group'>
              <input type='text' className='form-control' id='addressLine3' placeholder={fieldNames.addressLine3.label} required value={user.addressLine3} onChange={handleChange}/>  
            </div>
          </div>
        </div>

        <div className='row form-group'>
          <label htmlFor='city' className='col-sm-4 col-form-label input-label'>{fieldNames.city.label}</label>
          <div className='col-sm-8'>
            <div className='input-group'>
              <input type='text' className='form-control' id='city' placeholder={fieldNames.city.label} required value={user.city} onChange={handleChange}/>  
            </div>
          </div>
        </div>

        <div className='row form-group'>
          <label htmlFor='state' className='col-sm-4 col-form-label input-label'>{fieldNames.state.label}</label>
          <div className='col-sm-8'>
            <div className='input-group'>
              <input type='text' className='form-control' id='state' placeholder={fieldNames.state.label} required value={user.state} onChange={handleChange}/>  
            </div>
          </div>
        </div>

        <div className='row form-group'>
          <label htmlFor='postCode' className='col-sm-4 col-form-label input-label'>{fieldNames.postCode.label}</label>
          <div className='col-sm-8'>
            <div className='input-group'>
              <input type='text' className='form-control' id='postCode' placeholder={fieldNames.postCode.label} required value={user.postCode} onChange={handleChange}/>  
            </div>
          </div>
        </div>

        <div className='row form-group'>
          <label htmlFor='Role' className='col-sm-4 col-form-label input-label'>Role</label>
          <div className='col-sm-8'>
            <div className='input-group'>
              <input type='text' className='form-control' id='Role' placeholder='Role' required value={_.startCase(user.role)} disabled/>  
            </div>
          </div>
        </div>
        {
          user.status === 'ACTIVE' ? (
            <div className='row form-group'>
              <div className='col-sm-4'></div>
              <div className='col-sm-8 text-center'>
                <button type='reset' className='btn btn-danger' onClick={(event) => changeUserStatus('INACTIVE')}>Deactivate</button>
                <span className='mx-2'></span>
                <button type='submit' className='btn btn-primary' onClick={(event) => updateBasicInfo(event)}>Update</button>
              </div>
            </div>
          ) : ''
        }
        
      </React.Fragment>
    )
  }
}

export default BasicInfo;