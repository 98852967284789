import React, {useState, useEffect} from 'react';
import { useParams } from "react-router-dom";
import Avatar from 'react-avatar';
import _ from 'lodash';

import makeAPIRequest from '../../../helpers/makeAPIRequest';

import BasicInfo from '../BasicInfo';
import CreditTermInfo from '../CreditTermInfo';
import SellerInfo from '../SellerInfo';
import Spinner from '../../layouts/Spinner';

import './MemberDetail.css';

const MemberDetail = () => {
  
  const [user, setUser] = useState(null);
  const { userId } = useParams();

  const getUserDetails = async () => {
    const userDetail = await makeAPIRequest('get', `users/members/${userId}`, {})
    setUser(userDetail);
  }

  useEffect(() => {
    getUserDetails();
  }, []);
  
  if( !user ) {
    return (
      <Spinner />
    )
  } else {
    return (
      <div id="memberDetailContainer">
        <h3>Member Detail</h3>
        <hr/>
        <div className='row'>
          <div className='col-12 col-sm-12 col-md-12 col-lg-6'>
            <div className='p-4 border bg-light mb-3'>
              <div className='row'>
                <div className='col-3'>
                {
                  user.userPhotoURL ? <Avatar className='avatar-img' round={true} src={user.userPhotoURL}></Avatar> : 
                (user.displayName ? <Avatar name={user.displayName } className='avatar-img' round={true} /> : 
                  <Avatar name={user.email} className='avatar-img' round={true} />)
                }
                </div>
                <div className='col-9'><h4 className='card-title' style={{fontSize:'1.125rem', marginBottom:'0', display:'block'}}>{user.displayName}</h4>
                <p className='card-text' style={{fontSize:'0.875rem', display:'block', color:'#677788'}}>{user.email}</p></div>
              </div>
            </div>  
            <div className='p-4 border bg-light'>
              <BasicInfo userId={userId} basicInfo={user} getUserDetails={getUserDetails}/>
            </div> 
          </div>
          
          <div className='col-12 col-sm-12 col-md-12 col-lg-6'>
            <div className='p-4 border bg-light mb-3'>
              <CreditTermInfo userId={userId} ctInfo={_.get(user, 'creditTermInfo', false)} getUserDetails={getUserDetails}/>
            </div>
            <div className='p-4 border bg-light mb-3'>
              <SellerInfo userId={userId} sInfo={_.get(user, 'sellerInfo', false)} getUserDetails={getUserDetails}/>
            </div>
          </div>
        </div>
      </div>
    )
  }
  
}

export default MemberDetail;