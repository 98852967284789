import React, { useRef, useContext, useState } from "react";
import AuthContext from "../../context/auth/authContext";
import { useHistory } from "react-router-dom";

import Logo from "../../images/logo.png";

const Login = () => {
  const authContext = useContext(AuthContext);
  const history = useHistory();
  const emailRef = useRef();
  const passwordRef = useRef();

  const [alert, setAlert] = useState({show: false, message: ''});

  const handleSubmitSignup = async (e) => {
    setAlert({show:false, message: ''});
    e.preventDefault();

    authContext.setLoading(true);
    try {
      await authContext.login(
        emailRef.current.value,
        passwordRef.current.value
      );
      
      authContext.setIsLoggedIn(true);
      history.push("/");
    } catch (error) {
      setAlert({show:true, message: error.message});
    }
    authContext.setLoading(false);
  };

  return (
    <div
      className='d-flex align-items-center justify-content-center mb-5 large-screen-footer'
      style={{ minHeight: "65vh" }}
    >
      <div className='w-100' style={{ maxWidth: "400px" }}>
        {
          alert.show ? (
            <div className="alert alert-danger" role="alert">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="mr-1 mb-1 bi bi-exclamation-triangle" viewBox="0 0 16 16">
              <path d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.146.146 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.163.163 0 0 1-.054.06.116.116 0 0 1-.066.017H1.146a.115.115 0 0 1-.066-.017.163.163 0 0 1-.054-.06.176.176 0 0 1 .002-.183L7.884 2.073a.147.147 0 0 1 .054-.057zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z"/>
              <path d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995z"/>
            </svg>
              {alert.message}
            </div>
          ) : ''
        }
        <div className='card'>
          <div className='card-body'>
            <div className="text-center p-3">
              <img src={Logo} style={{ maxHeight: "60px" }} alt='logo' />
            </div>
            <h2 className='mb-4 text-center'>Log in</h2>
            <form onSubmit={handleSubmitSignup} className='form-login'>
              <div className='form-group'>
                <label htmlFor='email'>Email</label>
                <input
                  className='form-control'
                  type='email'
                  id='email'
                  placeholder='Email'
                  ref={emailRef}
                />
              </div>
              <div className='form-group'>
                <label htmlFor='password'>Password</label>
                <input
                  className='form-control'
                  type='password'
                  id='password'
                  placeholder='Password'
                  ref={passwordRef}
                />
              </div>

              <button type='submit' className='btn btn-dark btn-block'>
                Login
              </button>
            </form>
          </div>
          
        </div>
      </div>
    </div>
  );
};

export default Login;
