import React, { useState, useEffect, useContext } from 'react';
import FeatherIcon from 'feather-icons-react';
import { useHistory } from 'react-router-dom';
import algoliasearch from 'algoliasearch';
import { InstantSearch, connectMenu } from 'react-instantsearch-dom';

import './Widget.css';

const searchClient = algoliasearch(process.env.REACT_APP_ALGOLIAAPPID, process.env.REACT_APP_ALGOLIAPUBKEY);
const USERSINDEX = process.env.REACT_APP_USERSINDEX

const Users = () => {
  const history = useHistory();

  const MapIcon = (defaultRefinement) => {
    switch (defaultRefinement) {
      case "INACTIVE":
        return "x-circle";
      case "ACTIVE":
        return "check-square";
      default:
        return "check-square";
    }
  };


  const MapBadge = (defaultRefinement) => {
    switch (defaultRefinement) {
      case "INACTIVE":
        return `badge badge-pill badge-danger pill-widget`;
      case "ACTIVE":
        return `badge badge-pill badge-success pill-widget`;
      default:
        return `badge badge-pill badge-success pill-widget`;
    }
  };

  const RefinementList = ({
    items,
    defaultRefinement,
  }) => (
    items.map(item =>
    (
      <div>
        {
          item.label == defaultRefinement ?
            <div className="row widget-link" onClick={event => goTo(item.label)}>
              <div className="col-9 border-bottom p-2"><FeatherIcon icon={MapIcon(defaultRefinement)} /> {item.label}</div>
              <div className="col-3 border-bottom p-2 text-right pr-3"><span className={MapBadge(defaultRefinement)}>{item.count}</span></div>
            </div> : ""
        }
      </div>
    )
    ));


  const CustomMenu = connectMenu(RefinementList);

  const goTo = async (status = '') => {
    history.push(`/admin/users/members`)
  }

  return (
    <React.Fragment>
      <div className="row mt-3 border-top-widget-red border-left border-right no-gutters">
        <div className="col pl-3 pt-2">
          <h5>Members</h5>
        </div>
      </div>
      <div className="row border no-gutters p-4">

        <div className="col">
          <InstantSearch
            indexName={USERSINDEX}
            searchClient={searchClient}>
            {
              <React.Fragment>
                <CustomMenu attribute="status" defaultRefinement="INACTIVE" />
                <CustomMenu attribute="status" defaultRefinement="ACTIVE" />
              </React.Fragment>
            }
          </InstantSearch>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Users;