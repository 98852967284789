import { RESET_CONTEXT, SET_LOADING, SET_SEARCH_PARAMS, SET_SEARCH_RESULT } from "../types";

const logSearchReducer = (state, action) => {
  switch (action.type) {
    case RESET_CONTEXT:
      return action.payload;
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case SET_SEARCH_PARAMS:
      return {
        ...state,
        searchParams: action.payload,
        loading: false,
      };   
    case SET_SEARCH_RESULT:
      return {
        ...state,
        searchResult: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default logSearchReducer;