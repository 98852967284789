import React, { useContext } from 'react';
import {Route, Switch } from "react-router-dom";

//Context
import AuthContext from "./context/auth/authContext";

//States
import UserSearchState from "./context/userSearch/UserSearchState";
import OrderSearchState from './context/orderSearch/OrderSearchState';
import LogSearchState from "./context/logSearch/LogSearchState";

//layouts
import Header from './components/layouts/Header';
import Nav from './components/layouts/Nav';
import Spinner from './components/layouts/Spinner';

//Pages
import Login from "./components/auth/Login";
import Dashboard from "./components/dashboard/Dashboard";
import Members from "./components/users/members/Members";
import MemberDetail from "./components/users/members/MemberDetail";
import Sellers from "./components/users/sellers/Sellers";
import SellerDetail from "./components/users/sellers/SellerDetail";
import ChangePassword from "./components/profile/ChangePassword";
import Products from "./components/products/Products";
import ProductDetail from "./components/productdetail/ProductDetail";
import ProductUpdate from "./components/productupdate/ProductUpdate";
import Orders from './components/order/Orders';
import OrderDetail from './components/order/OrderDetail'
import Wishlist from './components/wishlist/Wishlist'
import BCLog from "./components/auditlog/BCLog";

const Routes = () => {
  const authContext = useContext(AuthContext);
  
  if(authContext.isLoggedIn) {
    if( authContext.currentUser ) {
      return (
        <UserSearchState>
            <OrderSearchState>
              <LogSearchState>
              <Header/>
              <div className="container-fluid">
                <div className="row">
                  <Nav />
                  <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
                    <div className="pt-3 pb-2 mb-3">
                      <Switch>
                        { /* users management */ }
                        <Route exact path='/admin/users/members/view/:userId' component={MemberDetail} />  
                        <Route exact path='/admin/users/members' component={Members} />  
                        <Route exact path='/admin/users/sellers/view/:userId' component={SellerDetail} />  
                        <Route exact path='/admin/users/sellers' component={Sellers} />  
                        
                        { /* products management */ }
                        <Route exact path='/admin/products/status/:status' component={Products} />
                        <Route exact path='/admin/products/view/:productId' component={ProductDetail} />
                        <Route exact path='/admin/products/update/:productId' component={ProductUpdate} />
                        <Route exact path='/admin/products/wishlist' component={Wishlist} />

                        { /* order management */ }
                        <Route exact path='/admin/orders/status/:status' component={Orders} />
                        <Route exact path='/admin/orders/:poId' component={OrderDetail} />
                        
                        <Route exact path='/profile/update/password' component={ChangePassword} />
                        <Route exact path='/auditlog/bclog' component={BCLog} />
                        <Route path='/' component={Dashboard} />
                      </Switch>
                    </div>
                  </main>
                </div>
              </div>
              </LogSearchState>  
            </OrderSearchState>
        </UserSearchState>
      )
    } else {
      return <Spinner />
    }
  } else {
    return <Login />
  }
  
}

export default Routes;