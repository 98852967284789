import { app } from "../firebase";

export async function getCurrentUser() {
  const user = await app.auth().currentUser;
  return user;
}

export async function updateCurrentUser() {
  let user = await app.auth().currentUser;
  if (user) {
    user.reload()
    user = await app.auth().currentUser;
  }
  return user
}
