// all
export const RESET_CONTEXT = "RESET_CONTEXT";

//Auth State
export const SET_LOADING = "SET_LOADING";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const SET_ISLOGGEDIN = "SET_ISLOGGEDIN";

//Search State
export const SET_SEARCH_PARAMS = "SET_SEARCH_PARAMS";
export const SET_SEARCH_RESULT = "SET_SEARCH_RESULT";