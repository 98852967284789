import React from 'react';

import Users from '../widgets/Users';
import Products from '../widgets/Products';
import Orders from '../widgets/Orders';

import './Dashboard.css';

const Dashboard = () => {
  return (
    <React.Fragment>
      <span className="small float-right mt-4">Overview and Quick Links to things that needs users attention</span>
      <h3>Dashboard</h3>
      {/* <SalesPerformance /> */}
      <div className="row">
        <div className='col-6'>
          <Users />
        </div>
        <div className='col-6'>
          <Products />
        </div>
      </div>
      <div className="row">
        <div className='col-6'>
          <Orders />
        </div>
        <div className='col-6'>
          
        </div>
      </div>
    </React.Fragment>
  )
}

export default Dashboard;